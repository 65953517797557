import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
// validation Formik
import * as Yup from "yup";
import { Formik } from "formik";
import config from "../../../config";
import yupconfig from "../../../yupconfig";
import { InputField, MapAddressField, SelectField, TextareaField, SwitchField } from "../../../component/form/Field";
// import { sweatalert } from "../../../component/Sweatalert2";
import { ClientUpdateApi, ClientDetailApi } from "../../../store/slices/clientSlice";
import customToast from "component/Toastr";
import DatePicker from "react-multi-date-picker";
import moment from "moment";
import { MailchimpSubscribeApi } from "store/slices/mailchimpSlice";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import SpinLoader from "component/SpinLoader";
import { swalErrorHtml } from "component/Sweatalert2";
import { ExistError, IsExistApi } from "store/slices/salonSlice";
import { OpenCustomAlerts } from "store/slices/commonSlice";

const ClientEditForm = () => {
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState();
  const detail = useSelector((state) => state.client.isDetailData);
  const isExistError = useSelector((state) => state.salon.isExistError);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const inputRef = useRef();
  let cc_code = config.country_codes;
  let countryCodes = [];
  cc_code.map((name) => countryCodes.push({ value: name.code, label: name.flag +" "+ name.country_name +" ("+ name.code + ")" }));
  // console.log("detaildetaildetail",detail)
  // const handleCloseClientDetailModal = () => {
  //   dispatch(CloseClientDetailModal());
  // };
  const initialValues = {
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    date_of_birth: "",
    gender: "",
    address: "",
    street: "",
    suburb: "",
    state: "",
    postcode: "",
    description: "",
    country_code: "",
    send_sms_notification: 0,
    send_email_notification: 0,
    recieve_marketing_email: 0,
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().trim().max(100).label(t("First Name")).required(),
    last_name: Yup.string().trim().max(100).label(t("Last Name")).required(),
    email: Yup.string()
    .trim()
    .max(100)
    .email()
    .test(
      "emailOrPhone",
      t("Email or Phone is required"),
      function (value) {
        const phone_number = this.parent.phone_number;
        if (!value && !phone_number) {
          return false;
        }
        return true;
      }
    )
    .test(
      "Unique Email",
      isExistError.email,
      function (value) {
        if (isExistError.email) {
          return false;
        } else {
          return true;
        }
      }
    ),
  phone_number: Yup.string()
    .trim()
    .matches(config.phone_number_pattern, t(config.phone_number_433_error))
    .test(
      "emailOrPhone",
      t("Email or Phone is required"),
      function (value) {
        const email = this.parent.email;
        if (!value && !email) {
          return false;
        }
        return true;
      }
    )
    .test(
      "Unique Mobile Number",
      isExistError.phone_number,
      function (value) {
        if (isExistError.phone_number) {
          return false;
        } else {
          return true;
        }
      }
    ),
    date_of_birth: Yup.string().trim().label(t("Date Of Birth")),
    country_code: Yup.string().trim().label(t("Country code")).required(),
    gender: Yup.string().trim().label(t("Gender")).nullable(),
    address: Yup.string().trim().label(t("Address")),
    street: Yup.string().trim().label(t("Street")),
    suburb: Yup.string().trim().label(t("Suburb")),
    state: Yup.string().trim().label(t("State")),
    postcode: Yup.string().trim().max(12).label(t("Postcode")),
    description: Yup.string().trim().label(t("Description")),
    send_sms_notification: Yup.bool().nullable(),
    send_email_notification: Yup.bool().nullable(),
    recieve_marketing_email: Yup.bool().nullable(),
  });
  yupconfig();

  const handleClientSubmit = (values, { setErrors, setStatus, setSubmitting }) => {
    setLoading(true);
    try {
      dispatch(ClientUpdateApi(values)).then((action) => {
        if (action.meta.requestStatus === "fulfilled") {
          setStatus({ success: true });
          dispatch(ClientDetailApi({ id: action.payload.id }));
          setLoading(false);
          customToast.success(`${t("Updated Successfully")}`);
          dispatch(MailchimpSubscribeApi({ id: action.payload && action.payload.id })).then((actionSubscribe) => {
            if (actionSubscribe.meta.requestStatus === "fulfilled") {
              // console.log(actionSubscribe);
            }
          });
        } else if (action.meta.requestStatus === "rejected") {
          const status = action.payload && action.payload.status;
          const errors = action.payload && action.payload.message && action.payload.message.errors;
          const response = action.payload && action.payload.message && action.payload.message;
          if (status === 422) {
            dispatch(
              OpenCustomAlerts({
                  isOpen: "open",
                  errors: (
                    <>
                      <p className="mb-2 text-danger salert text-justify">{response && response.message}</p>
                      <ul className="list-unstyled">
                        {errors &&
                          Object.keys(errors).map((a, n) => {
                            if (errors[a].length > 0) {
                              return (
                                <>
                                  {errors[a].map((as, ns) => {
                                    return (
                                      <li key={n + "-" + ns} className="text-danger salert form-text text-start">
                                        {as}
                                      </li>
                                    );
                                  })}
                                </>
                              );
                            }
                          })}
                      </ul>
                    </>
                  )
              })
          );
            setErrors(errors);
          }
          setStatus({ success: false });
          setSubmitting(false);
          setLoading(false);
        }
      });
    } catch (err) {
      setErrors(err.message);
      setStatus({ success: false });
      setLoading(false);
    }
  };

  const genderOptions = [
    { value: "Male", label: t("Male") },
    { value: "Female", label: t("Female") },
    { value: "Non-Binary", label: t("Non-Binary") },
    { value: "Undisclosed", label: t("Undisclosed") },
    { value: "Other", label: t("Other") },
  ];

  return (
    <React.Fragment>
      <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleClientSubmit}>
        {(formik) => {
          useEffect(() => {
            if (detail) {
              const fields = ["id", "first_name", "last_name", "email", "phone_number", "date_of_birth", "gender", "address", "street", "suburb", "state", "postcode", "description", "send_sms_notification", "send_email_notification", "recieve_marketing_email", "country_code"];
              fields.forEach((field) => {
                if (["send_sms_notification", "send_email_notification", "recieve_marketing_email"].includes(field)) {
                  formik.setFieldValue(field, parseInt(detail[field]), false);
                } else if(["date_of_birth"].includes(field)){
                  formik.setFieldValue(field, detail[field] ? detail[field] : "", false);
                }
                else{
                  formik.setFieldValue(field, detail[field] ? detail[field] : "", false);
                }
              });
            }
          }, [detail]);

          useEffect(() => {
            formik.setFieldError("email", isExistError.email ? isExistError.email : formik.errors.email);
            formik.setFieldError("phone_number", isExistError.phone_number ? isExistError.phone_number : formik.errors.phone_number);
          }, [isExistError]);

          return (
            <form noValidate onSubmit={formik.handleSubmit} className="px-1">
              <LoadScript
                googleMapsApiKey={config.GOOGLE_API_KEY}
                libraries={["places"]}
                loadingElement={
                  <div className="loader-spinner-center">
                    <SpinLoader />
                  </div>
                }
              >
                <div className="row">
                  <div className="col-sm-6 mb-3">
                    <InputField type="text" name="first_name" value={formik.values.first_name} label={t("First Name")} controlId="clientForm-first_name" />
                  </div>
                  <div className="col-sm-6 mb-3">
                    <InputField type="text" name="last_name" value={formik.values.last_name} label={t("Last Name")} controlId="clientForm-last_name" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3 mb-3">
                    <SelectField name="country_code" placeholder={t("-Select-")} value={formik.values.country_code} options={countryCodes} label={t("Country code")} controlId="clientForm-salon_type" />
                  </div>
                  <div className="col-sm-4 mb-3">
                    <InputField
                      type="text"
                      name="phone_number"
                      value={formik.values.phone_number}
                      mask={config.phone_number_mask}
                      label={t("Mobile")}
                      controlId="clientForm-phone_number"
                      onBlur={(event) => {
                        const fieldName = event.target.name;
                        // formik.setFieldTouched(fieldName, true);
                        dispatch(IsExistApi({ field_name: "phone_number", field_value: formik.values.phone_number, pagetype: "clientupdate", action: "afterlogin" })).then((action) => {
                          if (action.meta.requestStatus === "fulfilled") {
                            dispatch(ExistError({ ...isExistError, phone_number: "" }));
                          } else if (action.meta.requestStatus === "rejected") {
                            const status = action.payload && action.payload.status;
                            const errors = action.payload && action.payload.message && action.payload.message.errors;
                            // const message = action.payload && action.payload.message && action.payload.message.message;
                            if (status === 422) {
                              let error = [];
                              const keysError = Object.keys(errors);
                              const valuesError = Object.values(errors);
                              for (var i = 0; i < keysError.length; i++) {
                                // console.log(keysError[i]);
                                // console.log(valuesError[i]);
                                error[keysError[i]] = valuesError[i].length == 1 ? valuesError[i][0] : "";
                              }
                              dispatch(ExistError({ ...isExistError, phone_number: errors.phone_number[0] }));
                            }
                          }
                        });
                      }}
                    />
                  </div>
                  <div className="col-sm-5 mb-3">
                    <InputField
                      type="text"
                      name="email"
                      value={formik.values.email}
                      label={t("Email Address")}
                      controlId="clientForm-email"
                      onBlur={(event) => {
                        const fieldName = event.target.name;
                        // formik.setFieldTouched(fieldName, true);
                        dispatch(IsExistApi({ field_name: "email", field_value: formik.values.email, pagetype: "clientupdate", action: "afterlogin" })).then((action) => {
                          if (action.meta.requestStatus === "fulfilled") {
                            dispatch(ExistError({ ...isExistError, email: "" }));
                          } else if (action.meta.requestStatus === "rejected") {
                            const status = action.payload && action.payload.status;
                            const errors = action.payload && action.payload.message && action.payload.message.errors;
                            // const message = action.payload && action.payload.message && action.payload.message.message;
                            if (status === 422) {
                              let error = [];
                              const keysError = Object.keys(errors);
                              const valuesError = Object.values(errors);
                              for (var i = 0; i < keysError.length; i++) {
                                // console.log(keysError[i]);
                                // console.log(valuesError[i]);
                                error[keysError[i]] = valuesError[i].length == 1 ? valuesError[i][0] : "";
                              }
                              dispatch(ExistError({ ...isExistError, email: errors.email[0] }));
                            }
                          }
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 mb-3">
                    <div className="">
                      <label htmlFor="">{t("Date Of Birth")}</label>
                      <DatePicker
                        weekDays={config.weekDays}
                        className="purple"
                        renderButton={(direction, handleClick) => (
                          <a className="btn btn-transparent shadow-none" onClick={handleClick}>
                            {direction === "right" ? <i className="fas fa-chevron-right p-0"></i> : <i className="fas fa-chevron-left p-0"></i>}
                          </a>
                        )}
                        name="date_of_birth"
                        id="closeddateForm-date_of_birth"
                        value={formik.values.date_of_birth}
                        inputClass={(formik.touched && formik.touched.date_of_birth && formik.errors && formik.errors.date_of_birth ? "is-invalid" : "") + " form-control date"}
                        placeholder={t("Select Date")}
                        format={"DD/MM/YYYY"}
                        maxDate={new Date()}
                        onChange={(e) => {
                          let getselectedDatePicker = e ? moment(e?.toString(), "DD/MM/YYYY").format("DD/MM/YYYY") : null;
                          formik.setFieldValue("date_of_birth", getselectedDatePicker);
                        }}
                      />
                      {formik.touched && formik.touched.date_of_birth && formik.errors && formik.errors.date_of_birth && <div className="invalid-feedback d-block">{formik.errors.date_of_birth}</div>}
                    </div>
                    {/* <div className="">
                      <label htmlFor="">{t("Date Of Birth")}</label>
                      <DatePicker
                        weekDays={config.weekDays}
                        className="purple"
                        renderButton={(direction, handleClick) => (
                          <a className="btn btn-transparent shadow-none" onClick={handleClick}>
                            {direction === "right" ? <i className="fas fa-chevron-right p-0"></i> : <i className="fas fa-chevron-left p-0"></i>}
                          </a>
                        )}
                        name="date_of_birth"
                        id="closeddateForm-date_of_birth"
                        value={formik.values.date_of_birth}
                        inputClass={(formik.touched && formik.touched.date_of_birth && formik.errors && formik.errors.date_of_birth ? "is-invalid" : "") + " form-control date"}
                        placeholder={t("Select Date")}
                        format={"DD/MM/YYYY"}
                        maxDate={new Date()}
                        onChange={(e) => {
                          let getselectedDatePicker = e ? moment(e?.toString(), "DD/MM/YYYY").format("DD/MM/YYYY") : "";
                          // let getselectedDatePicker = e ? moment(e?.toString()).format("DD/MM/YYYY") : "";
                          // console.log(getselectedDatePicker);
                          formik.setFieldValue("date_of_birth", getselectedDatePicker);
                        }}
                      />
                      {formik.touched && formik.touched.date_of_birth && formik.errors && formik.errors.date_of_birth && <div className="invalid-feedback d-block">{formik.errors.date_of_birth}</div>}
                    </div> */}
                    {/* <InputField type="date" name="date_of_birth" value={formik.values.date_of_birth} label={t("Date Of Birth")} controlId="clientForm-date_of_birth" /> */}
                  </div>
                  <div className="col-sm-6 mb-3">
                    <SelectField name="gender" label={t("Gender")} options={genderOptions} placeholder={t("--Select--")} controlId="clientForm-gender" />
                  </div>
                </div>
                <div className="mb-3">
                  <StandaloneSearchBox
                    onLoad={(ref) => (inputRef.current = ref)}
                    onPlacesChanged={(e) => {
                      const [place] = inputRef.current.getPlaces();
                      let streetAddress = "";
                      if (place) {
                        formik.setFieldValue("address", place.formatted_address);
                        for (var i = 0; i < place.address_components.length; i++) {
                          for (var j = 0; j < place.address_components[i].types.length; j++) {
                            if (place.address_components[i].types[j] === "country") {
                              if (place.address_components.some(component => component.types.includes("administrative_area_level_1"))) {
                                const stateComponent = place.address_components.find(component => component.types.includes("administrative_area_level_1"));
                                const state = stateComponent.short_name || stateComponent.long_name;
                                formik.setFieldValue("state", state);
                              } else {
                                const countryName = place.address_components.find(component => component.types.includes("country")).long_name;
                                formik.setFieldValue("state", countryName);
                              }
                            } else if (place.address_components[i].types[j] == "postal_code") {
                              formik.setFieldValue("postcode", place.address_components[i].long_name);
                            } else if (place.address_components[i].types[j] == "street_number") {
                              streetAddress = place.address_components[i].long_name;
                            } else if (place.address_components[i].types[j] === "route") {
                              let subpremise = "";
                              let streetNumber = "";
                              if (place.address_components.some(component => component.types.includes("subpremise"))) {
                                subpremise = place.address_components.find(component => component.types.includes("subpremise")).long_name;
                              }
                              if (place.address_components.some(component => component.types.includes("street_number"))) {
                                streetNumber = place.address_components.find(component => component.types.includes("street_number")).long_name;
                              }
                              streetAddress = (subpremise ? subpremise + "/" : "") + streetNumber + " " + place.address_components[i].long_name;
                              formik.setFieldValue("street", streetAddress);
                            } else if (place.address_components[i].types[j] == "locality") {
                              formik.setFieldValue("suburb", place.address_components[i].long_name);
                            }
                          }
                        }
                      }
                    }}
                  >
                    <MapAddressField name="address" label={t("Address")} value={formik.values.address} placeholder={t("Start typing address")} controlId="clientForm-address" />
                  </StandaloneSearchBox>
                </div>
                <div className="row gx-2">
                  <div className="mb-3">
                    <InputField type="text" name="street" value={formik.values.street} label={t("Street")} controlId="clientForm-street" />
                  </div>
                </div>
                <div className="row gx-2">
                  <div className="col-sm-6 mb-3">
                    <InputField type="text" name="suburb" value={formik.values.suburb} label={t("Suburb")} controlId="clientForm-suburb" />
                  </div>
                  <div className="col-sm-3 col-6 mb-3">
                    <InputField type="text" name="state" value={formik.values.state} label={t("State")} controlId="clientForm-state" />
                  </div>
                  <div className="col-sm-3 col-6 mb-3">
                    <InputField type="text" name="postcode" value={formik.values.postcode} label={t("Postcode")} controlId="clientForm-postcode" />
                  </div>
                </div>
                <div className="mb-3">
                  <TextareaField type="text" name="description" placeholder={t("For example, allergic to latex")} value={formik.values.description} label={t("Client Notes")} controlId="clientForm-description" />
                </div>
                <div className="mb-3">
                  <label htmlFor="">{t("Notifications")}</label>
                  <SwitchField
                    name="send_sms_notification"
                    label={t("Send SMS notifications to client")}
                    controlId="clientForm-send_sms_notification"
                    value="1"
                    onChange={(e) => {
                      if (e.currentTarget.checked) {
                        setTimeout(() => {
                          formik.setFieldValue("send_sms_notification", 1, false);
                        }, 100);
                      } else {
                        setTimeout(() => {
                          formik.setFieldValue("send_sms_notification", 0, false);
                        }, 100);
                      }
                      formik.handleChange(e);
                    }}
                  />
                  <SwitchField
                    name="send_email_notification"
                    label={t("Send email notifications to client")}
                    controlId="clientForm-send_email_notification"
                    value="1"
                    onChange={(e) => {
                      if (e.currentTarget.checked) {
                        setTimeout(() => {
                          formik.setFieldValue("send_email_notification", 1, false);
                        }, 100);
                      } else {
                        setTimeout(() => {
                          formik.setFieldValue("send_email_notification", 0, false);
                        }, 100);
                      }
                      formik.handleChange(e);
                    }}
                  />
                  <SwitchField
                    name="recieve_marketing_email"
                    label={t("Client agrees to receive marketing emails")}
                    controlId="clientForm-recieve_marketing_email"
                    value="1"
                    onChange={(e) => {
                      if (e.currentTarget.checked) {
                        setTimeout(() => {
                          formik.setFieldValue("recieve_marketing_email", 1, false);
                        }, 100);
                      } else {
                        setTimeout(() => {
                          formik.setFieldValue("recieve_marketing_email", 0, false);
                        }, 100);
                      }
                      formik.handleChange(e);
                    }}
                  />
                </div>
                <div className="col-md-12 pe-2">
                  <button type="submit" className="btn btn-primary w-100 btn-lg" disabled={loading}>
                    {loading && <span className="spinner-border spinner-border-sm"></span>}
                    {t("Update Client")}
                  </button>
                </div>
              </LoadScript>
            </form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default ClientEditForm;
