import axios from "axios";
import { store } from "../store";
import config from "../config";
import authHeader from "./auth-header";
import moment from "moment";

const API_URL = config.API_URL;

const create = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const formData = new FormData();
  for (let value in values) {
    if (["addonservices", "addanotherservices", "service_deposit"].includes(value) && values[value] && typeof values[value] === "object") {
      formData.append(value, JSON.stringify(values[value]));
    } else {
      formData.append(value, values[value]);
    }
  }
  const action = "afterlogin/appointment/store";
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const update = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const clickEvent = values && values.clickEvent;
  const formData = new FormData();
  for (let value in values) {
    if (["addonservices", "addanotherservices", "service_deposit"].includes(value) && values[value] && typeof values[value] === "object") {
      formData.append(value, JSON.stringify(values[value]));
    } else {
      formData.append(value, values[value]);
    }
  }
  let action = "afterlogin/appointment/update/" + values.id;
  if (clickEvent && clickEvent === "statusupdate") {
    action = "afterlogin/appointment/status/" + values.id;
  }
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const view = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const sort = values && values.sort;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  const result = values && values.result ? values.result : "";
  const optionType = values && values.optionType ? values.optionType : "";
  //Appointment Calender view parameter
  const start_date = values && values.start_date ? moment(values.start_date).format("YYYY-MM-DD HH:mm:ss") : "";
  const end_date = values && values.end_date ? moment(values.end_date).format("YYYY-MM-DD HH:mm:ss") : "";
  const timezone = values && values.timezone ? values.timezone : "";
  const type = values && values.type ? values.type : "";
  const staff_id = values && values.staff_id ? values.staff_id : "";
  const resource_item_id = values && values.resource_item_id ? values.resource_item_id : "";
  const resource_id = values && values.resource_id ? values.resource_id : "";
  const formName = values && values.formName ? values.formName : "";
  const showdate = values && values.showdate ? moment(values.showdate).format("YYYY-MM-DD HH:mm:ss") : "";
  const showstartdate = values && values.showstartdate ? moment(values.showstartdate).format("YYYY-MM-DD HH:mm:ss") : "";
  const showenddate = values && values.showenddate ? moment(values.showenddate).format("YYYY-MM-DD HH:mm:ss") : "";

  //Appointment Calender view parameter close
  const filter = values && values.filter ? JSON.stringify(values.filter) : "";
  if (filter === "") {
    store.dispatch({ type: "appointment/closeAppointmentFilter" });
  }
  let sortstring = "";
  if (sort) {
    let sortArray = [];
    Object.keys(sort).map(function (key, index) {
      return (sortArray[index] = `sort[${key}]=${sort[key]}`);
    });
    if (sortArray.length > 0) {
      let jsort = sortArray.join("&");
      sortstring = jsort;
    }
  }
  let pagination = true;
  if (values) {
    if (values.id || start_date) {
      pagination = false;
    }
    if (values.option) {
      pagination = false;
    }
    if (values.pagination == false) {
      pagination = values.pagination;
    }
    if (values.pagination == true) {
      pagination = values.pagination;
    }
  }

  const action = page ? `afterlogin/appointment/view?page=${page}&${sortstring}` : `afterlogin/appointment/view?${sortstring}`;
  const data = {
    auth_key: auth_key,
    action: action,
    pagination: pagination, //true or false
    id: values && values.id ? values.id : "",
    result: result, //business_name,owner_name
    filter: filter, //business_name,owner_name
    option: values && values.option ? values.option : "",
    optionType: optionType,
    client_id: values && values.client_id ? values.client_id : "",
    start_date: start_date,
    end_date: end_date,
    timezone: timezone,
    type: type,
    staff_id: staff_id,
    resource_item_id,
    resource_id,
    formName,
    showdate: showdate,
    showstartdate: showstartdate,
    showenddate: showenddate,
    page_name: values && values.page_name ? values.page_name : "",
    singlestaffaccess: values && values.singlestaffaccess ? values.singlestaffaccess : ""
  };
  return axios.post(next_page_url ? `${next_page_url}&${sortstring}` : API_URL + action, data, { headers: authHeader() });
};
const clientappointmentview = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const sort = values && values.sort;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  const result = values && values.result ? values.result : "";
  const optionType = values && values.optionType ? values.optionType : "";
  //Appointment Calender view parameter
  const start_date = values && values.start_date ? moment(values.start_date).format("YYYY-MM-DD HH:mm:ss") : "";
  const end_date = values && values.end_date ? moment(values.end_date).format("YYYY-MM-DD HH:mm:ss") : "";
  const timezone = values && values.timezone ? values.timezone : "";
  const type = values && values.type ? values.type : "";
  const staff_id = values && values.staff_id ? values.staff_id : "";
  const resource_item_id = values && values.resource_item_id ? values.resource_item_id : "";
  const resource_id = values && values.resource_id ? values.resource_id : "";
  const formName = values && values.formName ? values.formName : "";
  const showdate = values && values.showdate ? moment(values.showdate).format("YYYY-MM-DD HH:mm:ss") : "";
  const showstartdate = values && values.showstartdate ? moment(values.showstartdate).format("YYYY-MM-DD HH:mm:ss") : "";
  const showenddate = values && values.showenddate ? moment(values.showenddate).format("YYYY-MM-DD HH:mm:ss") : "";

  //Appointment Calender view parameter close
  const filter = values && values.filter ? JSON.stringify(values.filter) : "";
 
  let sortstring = "";
  if (sort) {
    let sortArray = [];
    Object.keys(sort).map(function (key, index) {
      return (sortArray[index] = `sort[${key}]=${sort[key]}`);
    });
    if (sortArray.length > 0) {
      let jsort = sortArray.join("&");
      sortstring = jsort;
    }
  }
  let pagination = true;
  if (values) {
    if (values.id || start_date) {
      pagination = false;
    }
    if (values.option) {
      pagination = false;
    }
    if (values.pagination == false) {
      pagination = values.pagination;
    }
    if (values.pagination == true) {
      pagination = values.pagination;
    }
  }

  const action = page ? `afterlogin/appointment/view?page=${page}&${sortstring}` : `afterlogin/appointment/view?${sortstring}`;
  const data = {
    auth_key: auth_key,
    action: action,
    pagination: pagination, //true or false
    id: values && values.id ? values.id : "",
    result: result, //business_name,owner_name
    filter: filter, //business_name,owner_name
    option: values && values.option ? values.option : "",
    optionType: optionType,
    client_id: values && values.client_id ? values.client_id : "",
    start_date: start_date,
    end_date: end_date,
    timezone: timezone,
    type: type,
    staff_id: staff_id,
    resource_item_id,
    resource_id,
    formName,
    showdate: showdate,
    showstartdate: showstartdate,
    showenddate: showenddate,
    page_name: values && values.page_name ? values.page_name : "",
    singlestaffaccess: values && values.singlestaffaccess ? values.singlestaffaccess : ""
  };
  return axios.post(next_page_url ? `${next_page_url}&${sortstring}` : API_URL + action, data, { headers: authHeader() });
};

const deleted = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const action = `afterlogin/appointment/delete/${values.id}`;
  const data = {
    auth_key: auth_key,
    action: action,
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const reschedule = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  // const clickEvent = values && values.clickEvent;
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  }
  let action = "afterlogin/appointment/reschedule/" + values.id;
  formData.append("auth_key", auth_key);
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader({ contentType: "multipart/form-data" }) });
};

const appointmentautofillserviceoptions = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const sort = values && values.sort;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  const dropdown = values && values.dropdown ? values.dropdown : "";
  const result = values && values.result ? values.result : "";
  const fromPosition = values && values.fromPosition ? values.fromPosition : "";
  const toPosition = values && values.toPosition ? values.toPosition : "";
  const pagetype = values && values.pagetype ? values.pagetype : "";
  const resource_id = values && values.resource_id ? values.resource_id : "";
  const resource_item_id = values && values.resource_item_id ? values.resource_item_id : "";
  let sortstring = "";
  if (sort) {
    let sortArray = [];
    let sortSubArray = [];
    Object.keys(sort).map(function (key, index) {
      if (Object.keys(sort[key]).length > 0) {
        Object.keys(sort[key]).map(function (subkey, subindex) {
          sortSubArray[subindex] = `sort[${key}.${subkey}]=${sort[key][subkey]}`;
        });
      }
      if (key != "category") {
        sortArray[index] = `sort[${key}]=${sort[key]}`;
      }
    });
    if (sortSubArray.length > 0) {
      let jsubsort = sortSubArray.join("&");
      sortstring = jsubsort;
    }
    if (sortArray.length > 0) {
      let jsort = sortArray.join("&");
      sortstring = jsort;
    }
  }
  let pagination = true;
  if (values) {
    if (values.id) {
      pagination = false;
    }
    if (values.option) {
      pagination = false;
    }
    if (values.pagination) {
      pagination = values.pagination;
    }
  }
  const action = page ? `afterlogin/services/view?page=${page}&${sortstring}` : `afterlogin/services/view?${sortstring}`;
  let servicedata;
  if (dropdown) {
    servicedata = {
      auth_key: auth_key,
      action: action,
      pagination: false, //true or false
      id: "",
      staff_id: values && values.staff_id ? values.staff_id : "",
      // field: "name,description,duration,padding_time,color,service_booked_online,deposit_booked_online,deposit_booked_price", // first_name,last_name,email
      result: result, //business_name,owner_name
      pagetype: pagetype,
      resource_id: resource_id,
      resource_item_id: resource_item_id
    };
  } else {
    servicedata = {
      auth_key: auth_key,
      action: action,
      pagination: false, //true or false
      id: values && values.id ? values.id : "",
      staff_id: values && values.staff_id ? values.staff_id : "",
      // field: values && values.id ? "" : "name,description,duration,padding_time,color,service_booked_online,deposit_booked_online,deposit_booked_price", // first_name,last_name,email
      result: result, //business_name,owner_name
      option: values && values.option ? values.option : "",
      category_id: values && values.category_id ? values.category_id : "",
      fromPosition: fromPosition,
      toPosition: toPosition,
      pagetype: pagetype,
      resource_id: resource_id,
      resource_item_id: resource_item_id
    };
  }

  const data = servicedata;
  return axios.post(next_page_url ? `${next_page_url}&${sortstring}` : API_URL + action, data, { headers: authHeader() });
};

const appointmentpayfail = (values) => {
  const formData = new FormData();
  for (let value in values) {
    if (["error", 'paymentIntent'].includes(value) && values[value] && typeof values[value] === "object") {
      formData.append(value, JSON.stringify(values[value]));
    } else {
      formData.append(value, values[value]);
    }
  }
  const action = `afterlogin/appointment/appointmentpaymentfailed`;
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader() });
};

const appointmentpayconfirm = (values) => {
  const formData = new FormData();
  for (let value in values) {
    if (["paymentIntent"].includes(value) && values[value] && typeof values[value] === "object") {
      formData.append(value, JSON.stringify(values[value]));
    } else {
      formData.append(value, values[value]);
    }
  }
  const action = `afterlogin/appointment/appointmentpayment`;
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader() });
};
const refundpayfail = (values) => {
  const formData = new FormData();
  for (let value in values) {
    if (["error", 'paymentIntent'].includes(value) && values[value] && typeof values[value] === "object") {
      formData.append(value, JSON.stringify(values[value]));
    } else {
      formData.append(value, values[value]);
    }
  }
  const action = `afterlogin/appointment/refundpaymentfailed`;
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader() });
};

const refundpaymentstore = (values) => {
  const formData = new FormData();
  for (let value in values) {
    if (["paymentIntent", "addonservices", "addanotherservices"].includes(value) && values[value] && typeof values[value] === "object") {
      formData.append(value, JSON.stringify(values[value]));
    } else {
      formData.append(value, values[value]);
    }
  }
  const action = `afterlogin/appointment/refundstore`;
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader() });
};

const refundpayconfirm = (values) => {
  const formData = new FormData();
  for (let value in values) {
    if (["paymentIntent"].includes(value) && values[value] && typeof values[value] === "object") {
      formData.append(value, JSON.stringify(values[value]));
    } else {
      formData.append(value, values[value]);
    }
  }
  const action = `afterlogin/appointment/refundpayment`;
  formData.append("action", action);
  return axios.post(API_URL + action, formData, { headers: authHeader() });
};

const appointmentresourceitems = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const sort = values && values.sort;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  const result = values && values.result ? values.result : "";
  const service_id = values && values.service_id ? values.service_id : "";
  const action = "afterlogin/appointment/resourceitems";
  let resourcedata;
  resourcedata = {
    auth_key: auth_key,
    action: action,
    pagination: false,
    service_id: values && values.service_id ? values.service_id : '',
    option: values && values.option ? values.option : "",
    availebleTimeObj: values && values.availebleTimeObj ? values.availebleTimeObj : "",
    result: result,
  };

  const data = resourcedata;
  return axios.post(next_page_url ? `${next_page_url}&${sortstring}` : API_URL + action, data, { headers: authHeader() });
};

const salecarts = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const result = values && values.result ? values.result : "";
  const action = "afterlogin/sale/appointmentinvoice";
  let data = {
    auth_key: auth_key,
    action: action,
    client_id : values && values.client_id ? values.client_id : '',
    sale_id: values && values.sale_id ? values.sale_id : '',
    salon_id: values && values.salon_id ? values.salon_id : '',
    result: result,
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
}

const appointmentstaffoptions = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  const sort = values && values.sort;
  const page = values && values.page;
  const next_page_url = values && values.next_page_url;
  const result = values && values.result ? values.result : "";
  const dropdown = values && values.dropdown ? values.dropdown : "";
  const service_id = values && values.service_id ? values.service_id : "";
  const pagetype = values && values.pagetype ? values.pagetype : "";
  const getRow = values && values.getRow ? values.getRow : "";

  let sortstring = "";
  if (sort) {
    let sortArray = [];
    let sortSubArray = [];
    Object.keys(sort).map(function (key, index) {
      if (Object.keys(sort[key]).length > 0) {
        Object.keys(sort[key]).map(function (subkey, subindex) {
          sortSubArray[subindex] = `sort[${key}.${subkey}]=${sort[key][subkey]}`;
        });
      }
      if (key != "supplier") {
        sortArray[index] = `sort[${key}]=${sort[key]}`;
      }
    });
    if (sortSubArray.length > 0) {
      let jsubsort = sortSubArray.join("&");
      sortstring = jsubsort;
    }
    if (sortArray.length > 0) {
      let jsort = sortArray.join("&");
      sortstring = jsort;
    }
  }
  let pagination = true;
  if (values) {
    if (values.id) {
      pagination = false;
    }
    if (values.option) {
      pagination = false;
    }
    if (values.pagination) {
      pagination = values.pagination;
    }
    if (values.maxstaffAlert) {
      pagination = false;
    }
  }
  const action = page ? `afterlogin/staff/view?page=${page}&${sortstring}` : `afterlogin/staff/view?${sortstring}`;
  let staffdata;
  if (dropdown) {
    staffdata = {
      auth_key: auth_key,
      action: action,
      pagination: false, //true or false
      id: "",
      result: result, //business_name,owner_name
      pagetype: pagetype,
      getRow: getRow,
      option: values && values.option ? values.option : "",
      page_name: values && values.page_name ? values.page_name : "",
      singlestaffaccess: values && values.singlestaffaccess ? values.singlestaffaccess : ""
    };
  } else {
    staffdata = {
      auth_key: auth_key,
      action: action,
      pagination: pagination, //true or false
      id: values && values.id ? values.id : "",
      result: result, //business_name,owner_name
      option: values && values.option ? values.option : "",
      service_id: service_id,
      pagetype: pagetype,
      page_name: values && values.page_name ? values.page_name : "",
      singlestaffaccess: values && values.singlestaffaccess ? values.singlestaffaccess : ""
    };
  }
  const data = staffdata;
  return axios.post(next_page_url ? `${next_page_url}&${sortstring}` : API_URL + action, data, { headers: authHeader() });
};

const resourcenextavailable = (values) => {
  const auth = store.getState().auth;
  const auth_key = auth.user.auth_key;
  
  const action = "afterlogin/appointment/nextresourceitemtime";

  const data = {
    auth_key: auth_key,
    action: action,
    ...values
  };
  return axios.post(API_URL + action, data, { headers: authHeader() });
};

const appointmentApiController = {
  create,
  update,
  view,
  deleted,
  reschedule,
  appointmentautofillserviceoptions,
  appointmentpayconfirm,
  appointmentpayfail,
  refundpaymentstore,
  refundpayfail,
  refundpayconfirm,
  appointmentresourceitems,
  salecarts,
  appointmentstaffoptions,
  resourcenextavailable,
  clientappointmentview
};
export default appointmentApiController;
