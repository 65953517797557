import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { ClientSubscriptionListViewApi } from "store/slices/clientsubscriptionSlice";
import config from "../../../../config";
import PaginationLoader from "component/PaginationLoader";
import SubscriptionListview from "./SubscriptionListview";
import ApiStatus from "component/ApiStatus";

const Subscription = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const currentUser = auth.user;
  const role_id = currentUser && currentUser.role_id;
  const access = useSelector((state) => state.salonmodule.isAccess);
  const detail = useSelector((state) => state.client.isDetailData);
  const clientsubscriptionApiStatus = useSelector((state) => state.clientsubscription.isApiStatus);
  const clientsubscriptiondata = useSelector((state) => state.clientsubscription.isListView);
  const detailTab = useSelector((state) => state.client.isClientDetailTab);

  useEffect(() => {
    dispatch(ClientSubscriptionListViewApi({ client_id: detail.id }));
  }, [detailTab]);
  
  const fetchDataList = () => {
    dispatch(
      ClientSubscriptionListViewApi({
        next_page_url:
          clientsubscriptiondata && clientsubscriptiondata.next_page_url,
        client_id: detail.id,
      })
    );
  };

  const [isFetching, setIsFetching] = useState(false);
  const loadMoreItemsList = () => {
    setIsFetching(true);
    dispatch(
      ClientSubscriptionListViewApi({
        next_page_url:
          clientsubscriptiondata && clientsubscriptiondata.next_page_url,
        client_id: detail.id,
      })
    );
    //mocking an API call
    setTimeout(() => {
      setIsFetching(false);
    }, 2000);
  };

  return (
    <>
      <div className="" id="client-subscription">
        {clientsubscriptionApiStatus && clientsubscriptionApiStatus.ClientSubscriptionListViewApi === "succeeded" && clientsubscriptiondata && clientsubscriptiondata.data && clientsubscriptiondata.data.length > 0 ? (
          <InfiniteScroll
            dataLength={clientsubscriptiondata.data.length}
            next={fetchDataList}
            scrollableTarget="client-subscription"
            hasMore={clientsubscriptiondata.next_page_url ? true : false}
            loader={<PaginationLoader />}
            style={{
              overflow: clientsubscriptiondata.next_page_url
                ? "auto"
                : "inherit",
            }}>
            <SubscriptionListview
              currentUser={currentUser}
              view={clientsubscriptiondata.data}
              role_id={currentUser}
              access={access}
            />
            {!isFetching && clientsubscriptiondata.next_page_url && (
              <div className="col-2 m-auto p-3 text-center">
                <button onClick={loadMoreItemsList} className="btn btn-primary">
                  {t("More")}
                </button>
              </div>
            )}
          </InfiniteScroll>
        ) : (
          <>
            {clientsubscriptionApiStatus && clientsubscriptionApiStatus.ClientSubscriptionListViewApi === "loading" ? (
              <ApiStatus actionType="clientsubscriptionview" />
            ) : (
              <div className="complete-box text-center d-flex flex-column justify-content-center mt-xl-4">
                <div className="complete-box-wrp text-center">
                  <img
                    src={config.imagepath + "voucher.png"}
                    alt=""
                    className="mb-md-4 mb-3"
                  />
                  <h5 className="mb-2 fw-semibold">
                  No subscriptions purchased.
                  </h5>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Subscription;
