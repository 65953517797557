import { lazy } from "react";

// project imports
import MainLayout from "../layout/MainLayout";
import Loadable from "../component/Loadable";
import AuthGuard from "./../utils/route-guard/AuthGuard";
import config from "./../config";
// import { WebSocketProvider } from "context/WebSocketContext";

// dashboard routing
// const MainLayout = Loadable(lazy(() => import("../layout/MainLayout")));
const Dashboard = Loadable(lazy(() => import("../pages/dashboard")));
const Calendar = Loadable(lazy(() => import("../pages/calendar")));
const Sales = Loadable(lazy(() => import("../pages/sales")));
const Vouchers = Loadable(lazy(() => import("../pages/vouchers")));
const Subscriptions = Loadable(lazy(() => import("../pages/subscriptions")));
const Membership = Loadable(lazy(() => import("../pages/membership")));
const Clients = Loadable(lazy(() => import("../pages/clients")));
const Staff = Loadable(lazy(() => import("../pages/staff")));
const Services = Loadable(lazy(() => import("../pages/services")));
const Products = Loadable(lazy(() => import("../pages/products")));
const Reports = Loadable(lazy(() => import("../pages/reports")));
const Marketing = Loadable(lazy(() => import("../pages/marketing")));
const BirthdayCampaign = Loadable(lazy(() => import("../pages/marketing/BirthdayCampaign")));
const AppointmentCampaign = Loadable(lazy(() => import("../pages/marketing/AppointmentCampaign")));
const ReviewCampaign = Loadable(lazy(() => import("../pages/marketing/ReviewCampaign")));
const Account = Loadable(lazy(() => import("../pages/account")));
const StripeAccountReauth = Loadable(lazy(() => import("../pages/account/StripeAccountReauth")));
const StripeAccountReturn = Loadable(lazy(() => import("../pages/account/StripeAccountReturn")));
const Setting = Loadable(lazy(() => import("../pages/setting")));


// const Booking = Loadable(lazy(() => import("../pages/Booking")));
const AcademyArticle = Loadable(lazy(() => import("../pages/marketing/AcademyArticle")));
const Packages = Loadable(lazy(() => import("../pages/packages")));
const NoMatch = Loadable(lazy(() => import("../pages/errors/NoMatch")));
const NoFeatureAccess = Loadable(lazy(() => import("../pages/NoFeatureAccess")));
const NoStaffAccessPage = Loadable(lazy(() => import("../pages/NoStaffAccessPage")));
const Resources = Loadable(lazy(() => import("../pages/Resources")));
// const PackagePayment = Loadable(lazy(() => import("../pages/packages/Payments")));

const MainRoutes = {
  path: config.basePath + "/",
  element: (
    <AuthGuard>
        <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: config.basePath + "/dashboard",
      element: <Dashboard />,
    },
    {
      path: config.basePath + "/calendar",
      element: <Calendar />,
    },
    {
      path: config.basePath + "/sales",
      element: <Sales />,
    },
    {
      path: config.basePath + "/vouchers",
      element: <Vouchers />,
    },
    {
      path: config.basePath + "/subscriptions",
      element: <Subscriptions />,
    },
    {
      path: config.basePath + "/packages",
      element: <Membership />,
    },
    {
      path: config.basePath + "/clients",
      element: <Clients />,
    },
    {
      path: config.basePath + "/staff",
      element: <Staff />,
    },
    {
      path: config.basePath + "/services",
      element: <Services />,
    },
    {
      path: config.basePath + "/products",
      element: <Products />,
    },
    {
      path: config.basePath + "/reports",
      element: <Reports />,
    },
    {
      path: config.basePath + "/marketing",
      element: <Marketing />,
    },
    {
      path: config.basePath + "/appointment-campaign",
      element: <AppointmentCampaign />,
    },
    {
      path: config.basePath + "/birthday-drip-campaign",
      element: <BirthdayCampaign />,
    },
    {
      path: config.basePath + "/review-campaign",
      element: <ReviewCampaign />,
    },
    {
      path: config.basePath + "/account",
      element: <Account />,
    },
    {
      path: config.basePath + "/reauth",
      element: <StripeAccountReauth />,
    },
    {
      path: config.basePath + "/return",
      element: <StripeAccountReturn />,
    },
    {
      path: config.basePath + "/settings",
      element: <Setting />,
    },
    {
      path: config.basePath + "/academyarticle/:id/:title",
      element: <AcademyArticle />,
    },
    {
      path: config.basePath + "/plans",
      element: <Packages />,
    },
    {
      path: config.basePath + "/nofeatureaccess",
      element: <NoFeatureAccess />,
    },
    {
      path: config.basePath + "/noaccess",
      element: <NoStaffAccessPage />,
    },

    {
      path: config.basePath + "/resource",
      element: <Resources />,
    },
    // {
    //   path: config.basePath + "/packages/:id/:title",
    //   element: <PackagePayment />,
    // },
    // { path: config.basePath + "/booking/:title/:id", element: <Booking /> },
    { path: "*", element: <NoMatch /> },
  ],
};

export default MainRoutes;
