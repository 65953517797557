import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import PaginationLoader from "component/PaginationLoader";
import { ClientInvoiceDetailApi, ClientInvoiceListViewApi, GetClientDetailTabInvoiceData, OpenClientInvoiceDetails, OpenPartialPaidInvoice } from "store/slices/clientinvoiceSlice";
import config from "../../../../config";
import Moment from "react-moment";
import ApiStatus from "component/ApiStatus";
import { clientDetailInvoicePrintApi } from "store/slices/printerSlice";
import ClientPaidInvoice from "./ClientPaidInvoice";
import PartialPaidInvoice from "./PartialPaidInvoice";
import RefundFromInvoiceModal from "./RefundFromInvoiceModal";
import PayByStripeModalClient from "./PayByStripeModalClient";
import getStripeClient from "pages/getStripeClient";
import { Elements } from "@stripe/react-stripe-js";
import CompletedSaleRefundModal from "./CompletedSaleRefundModal";
import { MoonLoader } from "react-spinners";

const Invoices = () => {
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState({});
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const currentUser = auth.user;
  const role_id = currentUser && currentUser.role_id;
  const stripePromise = getStripeClient();
  const access = useSelector((state) => state.salonmodule.isAccess);
  const clientinvoiceApiStatus = useSelector((state) => state.clientinvoice.isApiStatus);
  const invoiceViews = useSelector((state) => state.clientinvoice.isListView);
  const isOpenClientInvoiceDetailsTab = useSelector((state) => state.clientinvoice.isOpenClientInvoiceDetailsTab);
  const isOpenPartialPaidInvoice = useSelector((state) => state.clientinvoice.isOpenPartialPaidInvoice);
  const detail = useSelector((state) => state.client.isDetailData);
  const isOpenClientInvoiceRefundModal = useSelector((state) => state.clientinvoice.isOpenClientInvoiceRefundModal);
  const isOpenStripeRefundCompletedSaleModal = useSelector((state) => state.clientinvoice.isOpenStripeRefundCompletedSaleModal);
  const isOpenClientInvoiceStripePayModalClient = useSelector((state) => state.clientinvoice.isOpenClientInvoiceStripePayModalClient);

  const fetchDataInvoiceList = () => {
    dispatch(ClientInvoiceListViewApi({ client_id: detail.id, next_page_url: invoiceViews.next_page_url }));
  };

  const [isFetching, setIsFetching] = useState(false);
  const loadMoreItemsList = () => {
    setIsFetching(true);
    dispatch(ClientInvoiceListViewApi({ next_page_url: invoiceViews && invoiceViews.next_page_url, client_id: detail.id }));
    //mocking an API call
    setTimeout(() => {
      setIsFetching(false);
    }, 2000);
  };
  return (
    <>
      <div className="drawer-header">
        <h2 className="mb-4 pe-md-5 mb-lg-5">
          {t("Invoices")}
          <a href="#" className="btn btn-outline btn-sm ms-2"
            onClick={() => {
              dispatch(clientDetailInvoicePrintApi({ invoice_for: "client_statement", client_id: detail.id })).then((action) => {
                setLoading(true)
                if (action.meta.requestStatus === "fulfilled") {
                  setLoading(false)
                  let fileURL = 'data:application/pdf;base64,' + action.payload.html;
                  let fileName = 'invoice.pdf';
                  let anchorlink = document.createElement('a');
                  anchorlink.href = fileURL;
                  anchorlink.download = fileName;
                  anchorlink.setAttribute('target', '_blank');
                  anchorlink.click();
                  var string = doc.output('datauristring');
                  var iframe = "<iframe width='100%' height='100%' src='" + string + "'></iframe>";
                  var newWindow = window.open();
                  newWindow.document.open();
                  newWindow.document.write(iframe);
                  newWindow.document.close();
                  var pdf_newTab = window.open("");
                  pdf_newTab.document.write(
                    "<html><head><title>" + encodeURIComponent(fileName) + "</title></head><body><iframe title='MY title' width='100%' height='100%' src='" + fileURL + "'></iframe></body></html>"
                  );
                }
              })
            }}
          >
            {t("Print Statement")}
          </a>
        </h2>
      </div>
      <div className="content-wrp" id="invoicelist">
        {clientinvoiceApiStatus && clientinvoiceApiStatus.ClientInvoiceListViewApi === "succeeded" && invoiceViews && invoiceViews.data && invoiceViews.data.length > 0 ? (
          <InfiniteScroll className="content-wrp row gx-0" dataLength={invoiceViews.data.length} next={fetchDataInvoiceList} scrollableTarget="invoicelist" hasMore={invoiceViews.next_page_url ? true : false} loader={<PaginationLoader />}>
            {Object.keys(invoiceViews.data).map((item, i) => {
              let id = invoiceViews.data[item].id;
              // let invoice = invoiceViews.data[item].invoice;
              // let invoice_name = invoice && invoice.name;
              // let invoice_credit = invoice && invoice.credit;
              // let cost = invoiceViews.data[item].cost;
              //let client_id = invoiceViews.data[item].client_id;
              //let note = invoiceViews.data[item].note;
              let invoicedate = invoiceViews.data[item].invoicedate;
              let total_cost = invoiceViews.data[item].cart_sum_cost;
              
              let status = invoiceViews.data[item].status;
              if(!total_cost && status === "Partial"){
                total_cost = invoiceViews.data[item].totaldeposit + " (Deposit)";
              }
              let is_deposit_refund = invoiceViews.data[item].is_deposit_refund;
              let appointmentData = invoiceViews.data[item] && invoiceViews.data[item].appointments && invoiceViews.data[item].appointments.length > 0 ? invoiceViews.data[item].appointments[0] : [];
             
              
              
              return (
                <div className="client-invoice-position position-relative" key={item && item.id ? item.id : i}>
                  <div className="event-box" data-id={id}>
                    <div className="d-flex align-items-start">
                      <a className="ms-auto invoice-link">
                        <img src="assets/images/past.png" alt="" />
                      </a>
                      <div className="w-100 px-md-3 px-2">
                        <h6 className="mb-1 color-wine fw-semibold">{"00" + id}</h6>
                        <h6 className="mb-1">
                          {t("Invoice date:")} <Moment format="DD MMMM YYYY">{invoicedate}</Moment>
                        </h6>
                        <h6 className="mb-0 fw-semibold">
                          ${total_cost ? total_cost : "0"}
                        </h6>
                      </div>
                      <span className="active">{is_deposit_refund === 1 ? "Refunded" : status}</span>
                    </div>
                  </div>
                  {(status === "Paid" || status === "Schedule") && (
                    <a className="cursor-pointer invoice-link invoice-tab-icon-position" onClick={() => {
                      setLoader((prevLoader) => ({ ...prevLoader, [i]: true }));
                      dispatch(ClientInvoiceDetailApi({ client_id: invoiceViews.data[item]?.client_id , id:invoiceViews.data[item]?.id })).then((action)=>{
                        if(action?.meta?.requestStatus == 'fulfilled'){
                          dispatch(OpenClientInvoiceDetails("open"));
                          setLoader((prevLoader) => ({ ...prevLoader, [i]: false }));
                        }
                      });
                      // dispatch(GetClientDetailTabInvoiceData(invoiceViews.data[item]));
                      // 
                    }}>
                      <img src={config.imagepath + "past.png"} alt="" />
                    </a>
                  )}
                  {(status !== "Paid") && ((status === "Partial" || is_deposit_refund === 1)) && (
                    <a className="cursor-pointer invoice-link invoice-tab-icon-position" onClick={() => {
                      setLoader((prevLoader) => ({ ...prevLoader, [i]: true }));
                      dispatch(ClientInvoiceDetailApi({ client_id: invoiceViews.data[item]?.client_id , id:invoiceViews.data[item]?.id })).then((action)=>{
                        if(action?.meta?.requestStatus == 'fulfilled'){
                          dispatch(OpenPartialPaidInvoice("open"));
                          setLoader((prevLoader) => ({ ...prevLoader, [i]: false }));
                        }
                      });
                      // dispatch(GetClientDetailTabInvoiceData(invoiceViews.data[item]));
                      // dispatch(OpenPartialPaidInvoice("open"));
                    }}>
                      <img src={config.imagepath + "past.png"} alt="" />
                    </a>
                  )}
                  {/* {invoiceViews?.data[item] && (invoiceViews.data[item]?.appointments?.length > 0 && invoiceViews.data[item]?.lastpayment?.length > 0) && status === "Partial" && (
                    <a className="cursor-pointer invoice-link invoice-tab-icon-position" onClick={() => {
                      dispatch(OpenPartialPaidInvoice("open"));
                      dispatch(GetClientDetailTabInvoiceData(invoiceViews.data[item]));
                    }}>
                      <img src={config.imagepath + "past.png"} alt="" />
                    </a>
                  )} */}
                  {/* {status !== "Paid" && (
                    <a className="cursor-pointer invoice-link invoice-tab-icon-position" onClick={() => {
                      dispatch(OpenPartialPaidInvoice("open"));
                      dispatch(GetClientDetailTabInvoiceData(invoiceViews.data[item]));
                    }}>
                      <img src={config.imagepath + "past.png"} alt="" />
                    </a>
                  )} */}
                  {loader[i] ? (
                  <div className="position-absolute" style={{ right:'15px',bottom:'25px' }}>
                  <span className="spinner-border spinner-border-sm"></span>
                  </div>
                  ) : null}
                </div>
              );
            })}
            {!isFetching && invoiceViews.next_page_url && (
              <div className="col-2 m-auto p-3 text-center">
                <button onClick={loadMoreItemsList} className="btn btn-primary">
                  {t("More")}
                </button>
              </div>
            )}
          </InfiniteScroll>
        ) : (
          <>
            {clientinvoiceApiStatus &&
              clientinvoiceApiStatus.ClientInvoiceListViewApi === "loading" ? (
              <ApiStatus actionType="clientinvoiceview" />
            ) : (
              <div className="complete-box text-center d-flex flex-column justify-content-center mt-xl-4">
                <div className="complete-box-wrp text-center">
                  <img src={config.imagepath + "nots.png"} alt="" className="mb-md-4 mb-3" />
                  <h5 className="mb-2 fw-semibold">{t("There are no invoices available.")}</h5>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {isOpenClientInvoiceDetailsTab && <ClientPaidInvoice />}
      {isOpenPartialPaidInvoice && <PartialPaidInvoice />}
      {isOpenClientInvoiceRefundModal && (<Elements stripe={stripePromise}><RefundFromInvoiceModal /></Elements>)}
      {isOpenStripeRefundCompletedSaleModal && (<Elements stripe={stripePromise}><CompletedSaleRefundModal /></Elements>)}
      {isOpenClientInvoiceStripePayModalClient && (<Elements stripe={stripePromise}><PayByStripeModalClient /></Elements>)}
    </>
  );
};

export default Invoices;
