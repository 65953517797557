import { lazy } from "react";

// project imports
import Loadable from "../component/Loadable";
import config from "../config";
import CustomLayout from "layout/CustomLayout";
import CustomGuard from "utils/route-guard/CustomGuard";

const NoMatch = Loadable(lazy(() => import("../pages/errors/NoMatch")));
const Booking = Loadable(lazy(() => import("../pages/Booking")));
const Consultationforms = Loadable(lazy(() => import("../pages/Consultationforms")));
const SubscriptionInvoice = Loadable(lazy(() => import("../pages/SubscriptionInvoice")));

// import { useSelector } from "react-redux";
// const { isLoggedIn } = useSelector((state) => state.auth);

const CustomRoutes = {
  path: config.basePath + "/",
  element: (
    <CustomGuard>
      <CustomLayout />
    </CustomGuard>
  ),
  children: [
    { path: config.basePath + "/booking/:title/:id", element: <Booking /> },
    // { path: config.basePath + "/consultationforms/:title/:id", element: <Consultationforms /> },
    { path: config.basePath + "/consultationforms/:id", element: <Consultationforms /> },
    { path: config.basePath + "/subscription-invoice/:id/:title", element: <SubscriptionInvoice /> },
    { path: "*", element: <NoMatch /> },
  ],
};

export default CustomRoutes;
