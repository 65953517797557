const config = {
  // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
  siteName: "Beauti",
  siteAddress: "3/100 Sunshine Road Brisbane 4000 07 3000 000",
  siteUrl: process.env.REACT_APP_PUBLIC_URL,
  baseUrl: process.env.REACT_APP_PUBLIC_URL,
  basePath: "",
  basename: "/login",
  logopath: "/images/logo.png", // /home/beautisoftware/public_html
  imagepath: "/images/",
  imageOnError: "/images/Frame1.png",
  defaultPath: "/calendar",
  API_URL: process.env.REACT_APP_API_URL,
  NODE_URL: process.env.REACT_APP_NODE_URL,
  BOOKING_LINK_URL: process.env.REACT_APP_PUBLIC_URL + "/booking",
  phone_number_mask: "9999-999-999",
  business_phone_number_mask: "9999-999-999 | 99-9999-9999",
  business_phone_number_nomask: "9999999999",
  phone_number_pattern: /^[0-9]{4}-[0-9]{3}-[0-9]{3}$/,
  business_phone_number_pattern: /^[0-9]{4}-[0-9]{3}-[0-9]{3}|^[0-9]{2}-[0-9]{4}-[0-9]{4}$/,
  business_phone_number_nopattern: /^[0-9]{4}[0-9]{3}[0-9]{3}$/,
  phone_number_433_error: "${path} must match the following: 9999-999-999",
  business_phone_number_error: "${path} must match the following: 9999-999-999 | 99-9999-9999",
  business_phone_number_10_error: "${path} must match the following: 9999999999",
  duration_pattern: /^([0-1]\d|2[0-3]):([0-5]\d)$/,
  duration_HM_error: "Only Allow hours and Minutes",
  google_api_key: "AIzaSyDtEOPPr627_yGNQJaZaBLFrKUF_GVNpvw",
  bing_api_key: "AisIvzcLWeb3W3FZt45Al5-dT3BQUh6bMbtEiTHXwZlfAw8lH4wCxuldUuX-lgX1",
  voucher_terms_condition: "This voucher is valid until the expiry date specified and cannot be redeemed or replaced after this date. We are not responsible for lost / stolen vouchers, and are not responsible for replacing a voucher that has been lost /stolen. This voucher is non-refundable and cannot be exchanged for cash. This voucher is not valid with any other offer and / or special. This voucher must be used by one person in one visit.",
  cardNumberPattern: /^[\d| ]{16,22}$/,
  cardNumberPattern_error: "Card Number is Invalid",
  weekDays: ["S", "M", "T", "W", "T", "F", "S"],
  business_type: ["Beauty", "Spa", "Medi-Aesthetics", "Wellness", "Hair", "Barber", "Tattoo", "Remedial", "Sports", "Other"],
  appointment_status: ["Scheduled", "Confirmed", "Completed", "Cancelled", "NoShow"],
  when_to_send: ["On the day of their birthday", "1 day before their birthday", "1 week before their birthday", "2 week before their birthday", "1 week after their birthday"],
  birthday_when_to_send: ["On the day of their birthday", "1 day before their birthday", "1 week before their birthday", "2 week before their birthday", "1 week after their birthday"],
  modalOpenTimeOut: 50,
  modalCloseTimeOut: 500,
  smscharacter: 480,
  booking_services_deposit: "$50.00",
  app_key: process.env.REACT_APP_LARAVEL_APP_KEY,
  GOOGLE_API_KEY: "AIzaSyA1fq3IQDc5C9Qon-6a4X0hDklhUlw3lJM",
  product_price: "retail_price", //retail or cost_price
  default_country_code: "+61",
  stripe_test_email: ['programmer106.dynamicdreamz@gmail.com', 'programmer93.dynamicdreamz@gmail.com'],
  developer_email: ['programmer1067.dynamicdreamz@gmail.com'],
  trial_mode_access_from_config: ["Subscriptions", "Memberships", "Automated Post Appointment Campaigns", "Automated Pre Appointment Campaigns", "One-Off SMS and Email Campaigns"],
  timeInterval: 15,
  // country_codes : [
  // "+1", "+7", "+20", "+27", "+30", "+31", "+32", "+33", "+34", "+36", "+39", "+40",
  // "+41", "+43", "+44", "+45", "+46", "+47", "+48", "+49", "+51", "+52", "+53", "+54",
  // "+55", "+56", "+57", "+58", "+60", "+61", "+62", "+63", "+64", "+65", "+66", "+81",
  // "+82", "+84", "+86", "+90", "+91", "+92", "+93", "+94", "+95", "+98", "+211", "+212",
  // "+213", "+216", "+218", "+220", "+221", "+222", "+223", "+224", "+225", "+226", "+227",
  // "+228", "+229", "+230", "+231", "+232", "+233", "+234", "+235", "+236", "+237", "+238",
  // "+239", "+240", "+241", "+242", "+243", "+244", "+245", "+246", "+248", "+249", "+250",
  // "+251", "+252", "+253", "+254", "+255", "+256", "+257", "+258", "+260", "+261", "+262",
  // "+263", "+264", "+265", "+266", "+267", "+268", "+269", "+290", "+291", "+297", "+298",
  // "+299", "+350", "+351", "+352", "+353", "+354", "+355", "+356", "+357", "+358", "+359",
  // "+370", "+371", "+372", "+373", "+374", "+375", "+376", "+377", "+378", "+379", "+380",
  // "+381", "+382", "+383", "+385", "+386", "+387", "+389", "+420", "+421", "+423", "+500",
  // "+501", "+502", "+503", "+504", "+505", "+506", "+507", "+508", "+509", "+590", "+591",
  // "+592", "+593", "+594", "+595", "+596", "+597", "+598", "+599", "+670", "+672", "+673",
  // "+674", "+675", "+676", "+677", "+678", "+679", "+680", "+681", "+682", "+683", "+685",
  // "+686", "+687", "+688", "+689", "+690", "+691", "+692", "+850", "+852", "+853", "+855",
  // "+856", "+880", "+886", "+960", "+961", "+962", "+963", "+964", "+965", "+966", "+967",
  // "+968", "+970", "+971", "+972", "+973", "+974", "+975", "+976", "+977", "+992", "+993",
  // "+994", "+995", "+996", "+998"
  // ],
  // country_codes: ["+1", "+61", "+91"],
  country_codes: [
    { name: "au", country_name: "Australia", code: "+61", flag: "🇦🇺" }
  ],
  // country_codes: [
  //   { name: "af", country_name: "Afghanistan", code: "+93", flag: "🇦🇫" },
  //   { name: "al", country_name: "Albania", code: "+355", flag: "🇦🇱" },
  //   { name: "dz", country_name: "Algeria", code: "+213", flag: "🇩🇿" },
  //   { name: "as", country_name: "American Samoa", code: "+1684", flag: "🇦🇸" },
  //   { name: "ad", country_name: "Andorra", code: "+376", flag: "🇦🇩" },
  //   { name: "ao", country_name: "Angola", code: "+244", flag: "🇦🇴" },
  //   { name: "ai", country_name: "Anguilla", code: "+1264", flag: "🇦🇮" },
  //   { name: "aq", country_name: "Antarctica", code: "+672", flag: "🇦🇶" },
  //   { name: "ag", country_name: "Antigua and Barbuda", code: "+1268", flag: "🇦🇬" },
  //   { name: "ar", country_name: "Argentina", code: "+54", flag: "🇦🇷" },
  //   { name: "am", country_name: "Armenia", code: "+374", flag: "🇦🇲" },
  //   { name: "aw", country_name: "Aruba", code: "+297", flag: "🇦🇼" },
  //   { name: "au", country_name: "Australia", code: "+61", flag: "🇦🇺" },
  //   { name: "at", country_name: "Austria", code: "+43", flag: "🇦🇹" },
  //   { name: "az", country_name: "Azerbaijan", code: "+994", flag: "🇦🇿" },
  //   { name: "bs", country_name: "Bahamas", code: "+1242", flag: "🇧🇸" },
  //   { name: "bh", country_name: "Bahrain", code: "+973", flag: "🇧🇭" },
  //   { name: "bd", country_name: "Bangladesh", code: "+880", flag: "🇧🇩" },
  //   { name: "bb", country_name: "Barbados", code: "+1242", flag: "🇧🇧" },
  //   { name: "by", country_name: "Belarus", code: "+375", flag: "🇧🇾" },
  //   { name: "be", country_name: "Belgium", code: "+32", flag: "🇧🇪" },
  //   { name: "bz", country_name: "Belize", code: "+501", flag: "🇧🇿" },
  //   { name: "bj", country_name: "Benin", code: "+229", flag: "🇧🇯" },
  //   { name: "bm", country_name: "Bermuda", code: "+1441", flag: "🇧🇲" },
  //   { name: "bt", country_name: "Bhutan", code: "+975", flag: "🇧🇹" },
  //   { name: "bo", country_name: "Bolivia", code: "+591", flag: "🇧🇴" },
  //   { name: "ba", country_name: "Bosnia And Herzegovina", code: "+387", flag: "🇧🇦" },
  //   { name: "bw", country_name: "Botswana", code: "+267", flag: "🇧🇼" },
  //   { name: "br", country_name: "Brazil", code: "+55", flag: "🇧🇷" },
  //   { name: "io", country_name: "British Indian Ocean Territory", code: "+246", flag: "🇮🇴" },
  //   { name: "vg", country_name: "British Virgin Islands", code: "+1284", flag: "🇻🇬" },
  //   { name: "bn", country_name: "Brunei Darussalam", code: "+673", flag: "🇧🇳" },
  //   { name: "bg", country_name: "Bulgaria", code: "+359", flag: "🇧🇬" },
  //   { name: "bf", country_name: "Burkina Faso", code: "+226", flag: "🇧🇫" },
  //   { name: "bi", country_name: "Burundi", code: "+257", flag: "🇧🇮" },
  //   { name: "kh", country_name: "Cambodia", code: "+855", flag: "🇰🇭" },
  //   { name: "cm", country_name: "Cameroon", code: "+237", flag: "🇨🇲" },
  //   { name: "ca", country_name: "Canada", code: "+1", flag: "🇨🇦" },
  //   { name: "cv", country_name: "Cape Verde", code: "+238", flag: "🇨🇻" },
  //   { name: "ky", country_name: "Cayman Islands", code: "+345", flag: "🇰🇾" },
  //   { name: "cf", country_name: "Central African Republic", code: "+236", flag: "🇨🇫" },
  //   { name: "td", country_name: "Chad", code: "+235", flag: "🇹🇩" },
  //   { name: "cl", country_name: "Chile", code: "+56", flag: "🇨🇱" },
  //   { name: "cn", country_name: "China", code: "+86", flag: "🇨🇳" },
  //   { name: "cx", country_name: "Christmas Island", code: "+61", flag: "🇨🇽" },
  //   { name: "cc", country_name: "Cocos (keeling) Islands", code: "+61", flag: "🇨🇨" },
  //   { name: "co", country_name: "Colombia", code: "+57", flag: "🇨🇴" },
  //   { name: "km", country_name: "Comoros", code: "+269", flag: "🇰🇲" },
  //   { name: "ck", country_name: "Cook Islands", code: "+682", flag: "🇨🇰" },
  //   { name: "cr", country_name: "Costa Rica", code: "+506", flag: "🇨🇷" },
  //   { name: "hr", country_name: "Croatia", code: "+385", flag: "🇭🇷" },
  //   { name: "cu", country_name: "Cuba", code: "+53", flag: "🇨🇺" },
  //   { name: "cy", country_name: "Cyprus", code: "+357", flag: "🇨🇾" },
  //   { name: "cz", country_name: "Czech Republic", code: "+420", flag: "🇨🇿" },
  //   { name: "ci", country_name: "Côte D'ivoire", code: "+225", flag: "🇨🇮" },
  //   { name: "cd", country_name: "Democratic Republic of the Congo", code: "+243", flag: "🇨🇩" },
  //   { name: "dk", country_name: "Denmark", code: "+45", flag: "🇩🇰" },
  //   { name: "dj", country_name: "Djibouti", code: "+253", flag: "🇩🇯" },
  //   { name: "dm", country_name: "Dominica", code: "+1767", flag: "🇩🇲" },
  //   { name: "do", country_name: "Dominican Republic", code: "+1849", flag: "🇩🇴" },
  //   { name: "ec", country_name: "Ecuador", code: "+593", flag: "🇪🇨" },
  //   { name: "eg", country_name: "Egypt", code: "+20", flag: "🇪🇬" },
  //   { name: "sv", country_name: "El Salvador", code: "+503", flag: "🇸🇻" },
  //   { name: "gq", country_name: "Equatorial Guinea", code: "+240", flag: "🇬🇶" },
  //   { name: "er", country_name: "Eritrea", code: "+291", flag: "🇪🇷" },
  //   { name: "ee", country_name: "Estonia", code: "+372", flag: "🇪🇪" },
  //   { name: "et", country_name: "Ethiopia", code: "+251", flag: "🇪🇹" },
  //   { name: "fk", country_name: "Falkland Islands (malvinas)", code: "+500", flag: "🇫🇰" },
  //   { name: "fo", country_name: "Faroe Islands", code: "+298", flag: "🇫🇴" },
  //   { name: "fj", country_name: "Fiji", code: "+679", flag: "🇫🇯" },
  //   { name: "fi", country_name: "Finland", code: "+358", flag: "🇫🇮" },
  //   { name: "fr", country_name: "France", code: "+33", flag: "🇫🇷" },
  //   { name: "gf", country_name: "French Guiana", code: "+594", flag: "🇬🇫" },
  //   { name: "pf", country_name: "French Polynesia", code: "+689", flag: "🇵🇫" },
  //   { name: "ga", country_name: "Gabon", code: "+241", flag: "🇬🇦" },
  //   { name: "gm", country_name: "Gambia", code: "+220", flag: "🇬🇲" },
  //   { name: "ge", country_name: "Georgia", code: "+995", flag: "🇬🇪" },
  //   { name: "de", country_name: "Germany", code: "+49", flag: "🇩🇪" },
  //   { name: "gh", country_name: "Ghana", code: "+233", flag: "🇬🇭" },
  //   { name: "gi", country_name: "Gibraltar", code: "+350", flag: "🇬🇮" },
  //   { name: "gr", country_name: "Greece", code: "+30", flag: "🇬🇷" },
  //   { name: "gl", country_name: "Greenland", code: "+299", flag: "🇬🇱" },
  //   { name: "gd", country_name: "Grenada", code: "+1473", flag: "🇬🇩" },
  //   { name: "gp", country_name: "Guadeloupe", code: "+590", flag: "🇬🇵" },
  //   { name: "gu", country_name: "Guam", code: "+1671", flag: "🇬🇺" },
  //   { name: "gt", country_name: "Guatemala", code: "+502", flag: "🇬🇹" },
  //   { name: "gg", country_name: "Guernsey", code: "+44", flag: "🇬🇬" },
  //   { name: "gn", country_name: "Guinea", code: "+224", flag: "🇬🇳" },
  //   { name: "gw", country_name: "Guinea-Bissau", code: "+245", flag: "🇬🇼" },
  //   { name: "gy", country_name: "Guyana", code: "+592", flag: "🇬🇾" },
  //   { name: "ht", country_name: "Haiti", code: "+509", flag: "🇭🇹" },
  //   { name: "va", country_name: "Holy See (Vatican City State)", code: "+379", flag: "🇻🇦" },
  //   { name: "hn", country_name: "Honduras", code: "+504", flag: "🇭🇳" },
  //   { name: "hk", country_name: "Hong Kong", code: "+852", flag: "🇭🇰" },
  //   { name: "hu", country_name: "Hungary", code: "+36", flag: "🇭🇺" },
  //   { name: "is", country_name: "Iceland", code: "+354", flag: "🇮🇸" },
  //   { name: "in", country_name: "India", code: "+91", flag: "🇮🇳" },
  //   { name: "id", country_name: "Indonesia", code: "+62", flag: "🇮🇩" },
  //   { name: "ir", country_name: "Iran", code: "+98", flag: "🇮🇷" },
  //   { name: "iq", country_name: "Iraq", code: "+964", flag: "🇮🇶" },
  //   { name: "ie", country_name: "Ireland", code: "+353", flag: "🇮🇪" },
  //   { name: "im", country_name: "Isle Of Man", code: "+44", flag: "🇮🇲" },
  //   { name: "il", country_name: "Israel", code: "+972", flag: "🇮🇱" },
  //   { name: "it", country_name: "Italy", code: "+39", flag: "🇮🇹" },
  //   { name: "jm", country_name: "Jamaica", code: "+1876", flag: "🇯🇲" },
  //   { name: "jp", country_name: "Japan", code: "+81", flag: "🇯🇵" },
  //   { name: "je", country_name: "Jersey", code: "+44", flag: "🇯🇪" },
  //   { name: "jo", country_name: "Jordan", code: "+962", flag: "🇯🇴" },
  //   { name: "kz", country_name: "Kazakhstan", code: "+7", flag: "🇰🇿" },
  //   { name: "ke", country_name: "Kenya", code: "+254", flag: "🇰🇪" },
  //   { name: "ki", country_name: "Kiribati", code: "+686", flag: "🇰🇮" },
  //   { name: "xk", country_name: "Kosovo", code: "+383", flag: "🇽🇰" },
  //   { name: "kw", country_name: "Kuwait", code: "+965", flag: "🇰🇼" },
  //   { name: "kg", country_name: "Kyrgyzstan", code: "+996", flag: "🇰🇬" },
  //   { name: "la", country_name: "Lao People's Democratic Republic", code: "+856", flag: "🇱🇦" },
  //   { name: "lv", country_name: "Latvia", code: "+371", flag: "🇱🇻" },
  //   { name: "lb", country_name: "Lebanon", code: "+961", flag: "🇱🇧" },
  //   { name: "ls", country_name: "Lesotho", code: "+266", flag: "🇱🇸" },
  //   { name: "lr", country_name: "Liberia", code: "+231", flag: "🇱🇷" },
  //   { name: "ly", country_name: "Libya", code: "+218", flag: "🇱🇾" },
  //   { name: "li", country_name: "Liechtenstein", code: "+423", flag: "🇱🇮" },
  //   { name: "lt", country_name: "Lithuania", code: "+370", flag: "🇱🇹" },
  //   { name: "lu", country_name: "Luxembourg", code: "+352", flag: "🇱🇺" },
  //   { name: "mo", country_name: "Macao Sar China", code: "+853", flag: "🇲🇴" },
  //   { name: "mk", country_name: "Macedonia", code: "+389", flag: "🇲🇰" },
  //   { name: "mg", country_name: "Madagascar", code: "+261", flag: "🇲🇬" },
  //   { name: "mw", country_name: "Malawi", code: "+265", flag: "🇲🇼" },
  //   { name: "my", country_name: "Malaysia", code: "+60", flag: "🇲🇾" },
  //   { name: "mv", country_name: "Maldives", code: "+960", flag: "🇲🇻" },
  //   { name: "ml", country_name: "Mali", code: "+223", flag: "🇲🇱" },
  //   { name: "mt", country_name: "Malta", code: "+356", flag: "🇲🇹" },
  //   { name: "mh", country_name: "Marshall Islands", code: "+692", flag: "🇲🇭" },
  //   { name: "mq", country_name: "Martinique", code: "+596", flag: "🇲🇶" },
  //   { name: "mr", country_name: "Mauritania", code: "+222", flag: "🇲🇷" },
  //   { name: "mu", country_name: "Mauritius", code: "+230", flag: "🇲🇺" },
  //   { name: "yt", country_name: "Mayotte", code: "+262", flag: "🇾🇹" },
  //   { name: "mx", country_name: "Mexico", code: "+52", flag: "🇲🇽" },
  //   { name: "fm", country_name: "Micronesia", code: "+691", flag: "🇫🇲" },
  //   { name: "md", country_name: "Moldova", code: "+373", flag: "🇲🇩" },
  //   { name: "mc", country_name: "Monaco", code: "+377", flag: "🇲🇨" },
  //   { name: "mn", country_name: "Mongolia", code: "+976", flag: "🇲🇳" },
  //   { name: "me", country_name: "Montenegro", code: "+382", flag: "🇲🇪" },
  //   { name: "ms", country_name: "Montserrat", code: "+1664", flag: "🇲🇸" },
  //   { name: "ma", country_name: "Morocco", code: "+212", flag: "🇲🇦" },
  //   { name: "mz", country_name: "Mozambique", code: "+258", flag: "🇲🇿" },
  //   { name: "mm", country_name: "Myanmar (Burma)", code: "+95", flag: "🇲🇲" },
  //   { name: "na", country_name: "Namibia", code: "+264", flag: "🇳🇦" },
  //   { name: "nr", country_name: "Nauru", code: "+674", flag: "🇳🇷" },
  //   { name: "np", country_name: "Nepal", code: "+977", flag: "🇳🇵" },
  //   { name: "nl", country_name: "Netherlands", code: "+31", flag: "🇳🇱" },
  //   { name: "nc", country_name: "New Caledonia", code: "+687", flag: "🇳🇨" },
  //   { name: "nz", country_name: "New Zealand", code: "+64", flag: "🇳🇿" },
  //   { name: "ni", country_name: "Nicaragua", code: "+505", flag: "🇳🇮" },
  //   { name: "ne", country_name: "Niger", code: "+227", flag: "🇳🇪" },
  //   { name: "ng", country_name: "Nigeria", code: "+234", flag: "🇳🇬" },
  //   { name: "nu", country_name: "Niue", code: "+683", flag: "🇳🇺" },
  //   { name: "nf", country_name: "Norfolk Island", code: "+1670", flag: "🇳🇫" },
  //   { name: "kp", country_name: "North Korea", code: "+672", flag: "🇰🇵" },
  //   { name: "mp", country_name: "Northern Mariana Islands", flag: "+850", flag: "🇲🇵" },
  //   { name: "no", country_name: "Norway", code: "+47", flag: "🇳🇴" },
  //   { name: "om", country_name: "Oman", code: "+968", flag: "🇴🇲" },
  //   { name: "pk", country_name: "Pakistan", code: "+92", flag: "🇵🇰" },
  //   { name: "pw", country_name: "Palau", code: "+680", flag: "🇵🇼" },
  //   { name: "ps", country_name: "Palestinian Territory, Occupied", code: "+970", flag: "🇵🇸" },
  //   { name: "pa", country_name: "Panama", code: "+507", flag: "🇵🇦" },
  //   { name: "pg", country_name: "Papua New Guinea", code: "+675", flag: "🇵🇬" },
  //   { name: "py", country_name: "Paraguay", code: "+595", flag: "🇵🇾" },
  //   { name: "pe", country_name: "Peru", code: "+51", flag: "🇵🇪" },
  //   { name: "ph", country_name: "Philippines", code: "+63", flag: "🇵🇭" },
  //   { name: "pn", country_name: "Pitcairn Islands", code: "+870", flag: "🇵🇳" },
  //   { name: "pl", country_name: "Poland", code: "+48", flag: "🇵🇱" },
  //   { name: "pt", country_name: "Portugal", code: "+351", flag: "🇵🇹" },
  //   { name: "pr", country_name: "Puerto Rico", code: "+1939", flag: "🇵🇷" },
  //   { name: "qa", country_name: "Qatar", code: "+974", flag: "🇶🇦" },
  //   { name: "cg", country_name: "Republic of the Congo - Brazzaville", code: "+242", flag: "🇨🇬" },
  //   { name: "ro", country_name: "Romania", code: "+40", flag: "🇷🇴" },
  //   { name: "ru", country_name: "Russian Federation", code: "+7", flag: "🇷🇺" },
  //   { name: "rw", country_name: "Rwanda", code: "+250", flag: "🇷🇼" },
  //   { name: "re", country_name: "Réunion", code: "+262", flag: "🇷🇪" },
  //   { name: "bl", country_name: "Saint Barthélemy", code: "+590", flag: "🇧🇱" },
  //   { name: "sh", country_name: "Saint Helena", code: "+290", flag: "🇸🇭" },
  //   { name: "kn", country_name: "Saint Kitts & Nevis", code: "+1869", flag: "🇰🇳" },
  //   { name: "lc", country_name: "Saint Lucia", code: "+1758", flag: "🇱🇨" },
  //   { name: "mf", country_name: "Saint Martin", code: "+590", flag: "🇲🇫" },
  //   { name: "pm", country_name: "Saint Pierre & Miquelon", code: "+508", flag: "🇵🇲" },
  //   { name: "vc", country_name: "Saint Vincent & The Grenadines", code: "+1784", flag: "🇻🇨" },
  //   { name: "ws", country_name: "Samoa", code: "+685", flag: "🇼🇸" },
  //   { name: "sm", country_name: "San Marino", code: "+378", flag: "🇸🇲" },
  //   { name: "st", country_name: "Sao Tome & Principe", code: "+239", flag: "🇸🇹" },
  //   { name: "sa", country_name: "Saudi Arabia", code: "+966", flag: "🇸🇦" },
  //   { name: "sn", country_name: "Senegal", code: "+221", flag: "🇸🇳" },
  //   { name: "rs", country_name: "Serbia", code: "+381", flag: "🇷🇸" },
  //   { name: "sc", country_name: "Seychelles", code: "+248", flag: "🇸🇨" },
  //   { name: "sl", country_name: "Sierra Leone", code: "+232", flag: "🇸🇱" },
  //   { name: "sg", country_name: "Singapore", code: "+65", flag: "🇸🇬" },
  //   { name: "sx", country_name: "Sint Maarten", code: "+1", flag: "🇸🇽" },
  //   { name: "sk", country_name: "Slovakia", code: "+421", flag: "🇸🇰" },
  //   { name: "si", country_name: "Slovenia", code: "+386", flag: "🇸🇮" },
  //   { name: "sb", country_name: "Solomon Islands", code: "+677", flag: "🇸🇧" },
  //   { name: "so", country_name: "Somalia", code: "+252", flag: "🇸🇴" },
  //   { name: "za", country_name: "South Africa", code: "+27", flag: "🇿🇦" },
  //   { name: "gs", country_name: "South Africa (South Georgia & South Sandwich Islands)", code: "+500", flag: "🇬🇸" },
  //   { name: "kr", country_name: "South Korea", code: "+82", flag: "🇰🇷" },
  //   { name: "ss", country_name: "South Sudan", code: "+211", flag: "🇸🇸" },
  //   { name: "es", country_name: "Spain", code: "+34", flag: "🇪🇸" },
  //   { name: "lk", country_name: "Sri Lanka", code: "+94", flag: "🇱🇰" },
  //   { name: "sd", country_name: "Sudan", code: "+249", flag: "🇸🇩" },
  //   { name: "sr", country_name: "Suriname", code: "+597", flag: "🇸🇷" },
  //   { name: "sz", country_name: "Swaziland", code: "+268", flag: "🇸🇿" },
  //   { name: "se", country_name: "Sweden", code: "+46", flag: "🇸🇪" },
  //   { name: "ch", country_name: "Switzerland", code: "+41", flag: "🇨🇭" },
  //   { name: "sy", country_name: "Syrian Arab Republic", code: "+963", flag: "🇸🇾" },
  //   { name: "tw", country_name: "Taiwan", code: "+886", flag: "🇹🇼" },
  //   { name: "tj", country_name: "Tajikistan", code: "+992", flag: "🇹🇯" },
  //   { name: "tz", country_name: "Tanzania", code: "+255", flag: "🇹🇿" },
  //   { name: "th", country_name: "Thailand", code: "+66", flag: "🇹🇭" },
  //   { name: "tl", country_name: "Timor-Leste", code: "+670", flag: "🇹🇱" },
  //   { name: "tg", country_name: "Togo", code: "+228", flag: "🇹🇬" },
  //   { name: "tk", country_name: "Tokelau", code: "+690", flag: "🇹🇰" },
  //   { name: "to", country_name: "Tonga", code: "+676", flag: "🇹🇴" },
  //   { name: "tt", country_name: "Trinidad & Tobago", code: "+1868", flag: "🇹🇹" },
  //   { name: "tn", country_name: "Tunisia", code: "+216", flag: "🇹🇳" },
  //   { name: "tr", country_name: "Turkey", code: "+90", flag: "🇹🇷" },
  //   { name: "tm", country_name: "Turkmenistan", code: "+993", flag: "🇹🇲" },
  //   { name: "tc", country_name: "Turks & Caicos Islands", code: "+1649", flag: "🇹🇨" },
  //   { name: "tv", country_name: "Tuvalu", code: "+688", flag: "🇹🇻" },
  //   { name: "ug", country_name: "Uganda", code: "+256", flag: "🇺🇬" },
  //   { name: "ua", country_name: "Ukraine", code: "+380", flag: "🇺🇦" },
  //   { name: "ae", country_name: "United Arab Emirates", code: "+971", flag: "🇦🇪" },
  //   { name: "gb", country_name: "United Kingdom", code: "+44", flag: "🇬🇧" },
  //   { name: "us", country_name: "United States", code: "+1", flag: "🇺🇸" },
  //   { name: "uy", country_name: "Uruguay", code: "+598", flag: "🇺🇾" },
  //   { name: "vi", country_name: "US Virgin Islands", code: "+1340", flag: "🇻🇮" },
  //   { name: "uz", country_name: "Uzbekistan", code: "+998", flag: "🇺🇿" },
  //   { name: "vu", country_name: "Vanuatu", code: "+678", flag: "🇻🇺" },
  //   { name: "ve", country_name: "Venezuela", code: "+58", flag: "🇻🇪" },
  //   { name: "vn", country_name: "Vietnam", code: "+84", flag: "🇻🇳" },
  //   { name: "wf", country_name: "Wallis And Futuna", code: "+681", flag: "🇼🇫" },
  //   { name: "ye", country_name: "Yemen", code: "+967", flag: "🇾🇪" },
  //   { name: "zm", country_name: "Zambia", code: "+260", flag: "🇿🇲" },
  //   { name: "zw", country_name: "Zimbabwe", code: "+263", flag: "🇿🇼" },
  //   { name: "ax", country_name: "Åland Islands", code: "+358", flag: "🇦🇽" },
  // ],
  STRIPO_API: "https://plugins.stripo.email/api/v1/auth",
  stripo_email_id: "china@beautisoftware.com",
  stripo_plugin_id: "a1f9840405a64278bde04c2aeeec8031",
  stripo_secret_key: "eyJpdiI6IktpWC84S1pWcmZVaWlFQmNQa3lWQVE9PSIsInZhbHVlIjoibjZwOEtxTEFxU09GSEdteFYzYXJqTGtMbXkrYVhZUTFzWW5uVnNHdTVIb3Fyb2huVXdsT1NyenJYMUkyQUFiMCIsIm1hYyI6ImEyNjM2NGJhZWMxYTMzODFjNjNiZjQ5YTA4YWJkNDkzZjIzYTc1NDg2NzZmMTdjNzI2Mzc4ZmVkMTViMzhkMTIiLCJ0YWciOiIifQ==",
  form_url: process.env.REACT_APP_PUBLIC_URL + "/consultationforms/", //http://localhost:3000/consultationforms/6b86b273ff34fce19d6b804eff5a3f5747ada4eaa22f1d49c01e52ddb7875b4b
  sendEmailPriceOneOff: "0.02", //AU dollar
  sendSMSPriceOneOff: "0.10",
  GOOGLE_RECAPTCHA_KEY:"6LcxPMMpAAAAAHH3SFBqaZ04cohDk0moMHfa6C13",
  GOOGLE_RECAPTCHA_KEY_TEST:"6LdCL8MpAAAAAMUlDjMpdJ-58gQq-zryQ0_-GllO"
};

// const config = {
//   // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
//   siteName: "Beauti",
//   siteAddress: "3/100 Sunshine Road Brisbane 4000 07 3000 000",
//   baseUrl: process.env.REACT_APP_PUBLIC_URL,
//   basePath: "/php/2022/salonapp",
//   basename: "/php/2022/salonapp/login",
//   logopath: "/php/2022/salonapp/images/logo.png",
//   imagepath: "/php/2022/salonapp/images/",
//   defaultPath: "/php/2022/salonapp/dashboard",
//   API_URL: "https://dddemo.net/php/2022/beauty/public/api/v1/",
//   phone_number_mask: "9999-999-999",
//   business_phone_number_mask: "9999-999-999 | 99-9999-9999",
//   phone_number_pattern: /^[0-9]{4}-[0-9]{3}-[0-9]{3}$/,
//   business_phone_number_pattern: /^[0-9]{4}-[0-9]{3}-[0-9]{3}|^[0-9]{2}-[0-9]{4}-[0-9]{4}$/,
//   phone_number_433_error: "${path} must match the following: 9999-999-999",
//   business_phone_number_error: "${path} must match the following: 9999-999-999 | 99-9999-9999",
//   duration_pattern: /^([0-1]\d|2[0-3]):([0-5]\d)$/,
//   duration_HM_error: "Only Allow hours and Minutes",
//   google_api_key: "AIzaSyDtEOPPr627_yGNQJaZaBLFrKUF_GVNpvw",
//   bing_api_key: "AisIvzcLWeb3W3FZt45Al5-dT3BQUh6bMbtEiTHXwZlfAw8lH4wCxuldUuX-lgX1",
//   voucher_terms_condition: "This voucher is valid until the expiry date specified and cannot be redeemed or replaced after this date. Aura is not responsible for lost / stolen vouchers, and is not responsible for replacing a voucher that has been lost /stolen. This voucher is non-refundable and cannot be exchanged for cash. This voucher is not valid with any other offer and / or special at Aura. This voucher must be used by one person in one visit.",
//   cardNumberPattern: /^[\d| ]{16,22}$/,
//   cardNumberPattern_error: "Card Number is Invalid",
//   weekDays: ["S", "M", "T", "W", "T", "F", "S"],
//   GOOGLE_API_KEY:"AIzaSyA1fq3IQDc5C9Qon-6a4X0hDklhUlw3lJM"
// };

// const config = {
//   // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
//   siteName: "Aura Beauty Salon",
//   siteAddress: "3/100 Sunshine Road Brisbane 4000 07 3000 000",
//   baseUrl: process.env.REACT_APP_PUBLIC_URL,
//   basePath: "/git/salonreact/build",
//   basename: "/git/salonreact/build/login",
//   logopath: "/git/salonreact/build/images/logo.png",
//   imagepath: "/git/salonreact/build/images/",
//   defaultPath: "/git/salonreact/build/dashboard",
//   API_URL: "http://127.0.0.1:8000/api/v1/",
//   phone_number_pattern: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
//   phone_number_334_error: "phone_number_334_error",
//   google_api_key: "AIzaSyDtEOPPr627_yGNQJaZaBLFrKUF_GVNpvw",
//   bing_api_key: "AisIvzcLWeb3W3FZt45Al5-dT3BQUh6bMbtEiTHXwZlfAw8lH4wCxuldUuX-lgX1",
//   voucher_terms_condition: "This voucher is valid until the expiry date specified and cannot be redeemed or replaced after this date. Aura is not responsible for lost / stolen vouchers, and is not responsible for replacing a voucher that has been lost /stolen. This voucher is non-refundable and cannot be exchanged for cash. This voucher is not valid with any other offer and / or special at Aura. This voucher must be used by one person in one visit.",
//   GOOGLE_API_KEY:"AIzaSyA1fq3IQDc5C9Qon-6a4X0hDklhUlw3lJM",
// };
// const config = {
//   // basename or basePath: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
//   siteName: "Beauti",
//   siteAddress: "3/100 Sunshine Road Brisbane 4000 07 3000 000",
//   siteUrl: process.env.REACT_APP_PUBLIC_URL,
//   baseUrl: process.env.REACT_APP_PUBLIC_URL,
//   basePath: "",
//   basename: "/login",
//   logopath: "/images/logo.png",
//   imagepath: "/images/",
//   imageOnError: "/images/Frame1.png",
//   defaultPath: "/calendar",
//   API_URL: process.env.REACT_APP_API_URL,
//   BOOKING_LINK_URL: process.env.REACT_APP_PUBLIC_URL + "/booking",
//   phone_number_mask: "9999-999-999",
//   business_phone_number_mask: "9999-999-999 | 99-9999-9999",
//   business_phone_number_nomask: "9999999999",
//   phone_number_pattern: /^[0-9]{4}-[0-9]{3}-[0-9]{3}$/,
//   business_phone_number_pattern: /^[0-9]{4}-[0-9]{3}-[0-9]{3}|^[0-9]{2}-[0-9]{4}-[0-9]{4}$/,
//   business_phone_number_nopattern: /^[0-9]{4}[0-9]{3}[0-9]{3}$/,
//   phone_number_433_error: "${path} must match the following: 9999-999-999",
//   business_phone_number_error: "${path} must match the following: 9999-999-999 | 99-9999-9999",
//   business_phone_number_10_error: "${path} must match the following: 9999999999",
//   duration_pattern: /^([0-1]\d|2[0-3]):([0-5]\d)$/,
//   duration_HM_error: "Only Allow hours and Minutes",
//   google_api_key: "AIzaSyDtEOPPr627_yGNQJaZaBLFrKUF_GVNpvw",
//   bing_api_key: "AisIvzcLWeb3W3FZt45Al5-dT3BQUh6bMbtEiTHXwZlfAw8lH4wCxuldUuX-lgX1",
//   voucher_terms_condition: "This voucher is valid until the expiry date specified and cannot be redeemed or replaced after this date. Aura is not responsible for lost / stolen vouchers, and is not responsible for replacing a voucher that has been lost /stolen. This voucher is non-refundable and cannot be exchanged for cash. This voucher is not valid with any other offer and / or special at Aura. This voucher must be used by one person in one visit.",
//   cardNumberPattern: /^[\d| ]{16,22}$/,
//   cardNumberPattern_error: "Card Number is Invalid",
//   weekDays: ["S", "M", "T", "W", "T", "F", "S"],
//   business_type: ["Beauty", "Spa", "Medi-Aesthetics", "Wellness", "Hair", "Barber", "Tattoo", "Remedial", "Sports", "Other"],
//   appointment_status: ["Scheduled", "Confirmed", "Completed", "Cancelled", "NoShow"],
//   when_to_send: ["Immediately before", "1 hour before", "2 hours before", "24 hours before", "2 days before", "3 days before", "4 days before", "5 days before", "1 week before", "2 weeks before", "4 weeks before"],
//   modalOpenTimeOut: 50,
//   modalCloseTimeOut: 500,
//   smscharacter: 123,
//   booking_services_deposit: "$50.00",
//   app_key: process.env.REACT_APP_LARAVEL_APP_KEY,
//   GOOGLE_API_KEY:"AIzaSyA1fq3IQDc5C9Qon-6a4X0hDklhUlw3lJM",
// };

export default config;
