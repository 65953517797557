import moment from "moment/moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { ClientSubscriptionListViewApi, SubscriptionCancellationApi } from "store/slices/clientsubscriptionSlice";
import customToast from "component/Toastr";
import { swalConfirm } from "component/Sweatalert2";
import { SubscriptionInvoiceApi } from "store/slices/subscriptionSlice";
import { useNavigate } from "react-router";

const SubscriptionListview = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [openModalTwo, setOpenModalTwo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ModalClickedData, setModalClickedData] = useState(null);
  const [printLoader, setPrintLoader] = useState(false);
  const views = props.view;
  const objectData = views && views.data ? views.data : views;

  const tableCellStyle = {
    background: '#f9f6f4',
    padding: '4px',
    textAlign: 'center',
  };
  const headerCellStyle = {
    ...tableCellStyle, // Extend the common styles
    textAlign: 'left',
    fontWeight: 'bold',
  };
  const serviceCellStyle = {
    ...tableCellStyle,
    textAlign: 'left',
  }

  return (
    <div>
      {openModal && (
        <>
          <div className={openModal ? "modal fade show" : "modal fade"} id="add-emailverification" tabIndex="-1" aria-labelledby="addemailverificationModalLabel" style={{ display: openModal ? "block" : "none" }}>
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body pb-0">
                  <div className="p-3">
                    <h5 className="mb-3 fw-semibold text-dark">{t("Confirm subscription cancel")}</h5>
                    <p className="fw-semibold mb-2">{t("Are you sure you want to cancel this subscription?")}</p>
                  </div>
                  <div className="row mt-3 bg-cream p-4 justify-content-center">
                    <div className="col-6">
                      <button className="preview btn me-1 cursor-pointer btn-preview w-100" type="button" onClick={() => {
                        setOpenModal(false);
                      }}>
                        {t("Cancel")}
                      </button>
                    </div>
                    <div className="col-6">
                      <button className="btn btn-primary w-100" type="button" disabled={loading} onClick={() => {
                        setLoading(true);
                        dispatch(SubscriptionCancellationApi({ client_id: ModalClickedData?.client_id, id: ModalClickedData?.id })).then((action) => {
                          if (action.meta.requestStatus === "fulfilled") {
                            dispatch(ClientSubscriptionListViewApi({ client_id: ModalClickedData?.client_id, subscription_id: ModalClickedData?.subscription_id, sale_id: ModalClickedData?.sale_id }));
                            setLoading(false);
                            setOpenModal(false);
                            customToast.success(t("Your subscription plan cancelled."));
                          } else {
                            setLoading(false);
                            customToast.error(t("Something went wrong."));
                          }
                        })
                      }}>
                        {loading && <span className="spinner-border spinner-border-sm"></span>}
                        {t("Yes Cancel")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {openModal && <div className="modal-backdrop show"></div>}
        </>
      )}
      {objectData && objectData.length > 0 &&
        Object.keys(objectData).map((item, i) => {
          // let nextpayment = next_payment_date ? moment(next_payment_date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY") : "";
          const stripe_pause_collection = objectData[item]?.stripe_pause_collection;
          let name = objectData[item]?.subscription_name ?? "";
          let amount = objectData[item]?.cost ?? 0;
          let client_id = objectData[item]?.client_id;
          let sale_id = objectData[item]?.sale_id;
          let subscription_id = objectData[item]?.subscription_id;
          let is_subscription_active = objectData[item]?.is_active;
          let is_subscription_cancel = objectData[item]?.is_plan_cancel;
          let lastpayment = objectData[item].payment_history && objectData[item].payment_history.length > 0 ? objectData[item].payment_history : [];
          const oneOffAmount = objectData[item]?.one_off_amount ?? 0;
          const Frequency = objectData[item]?.repeat_time_option ?? "";
          const FormattedFrequency = Frequency ? Frequency.slice(0, -2) : "";
          // const Duration = cartdata && cartdata.length > 0 ? cartdata[0].subscription && cartdata[0].subscription.repeat_time || "" : "";
          const no_of_payments = objectData[item]?.no_of_payments || "";
          const CommenceMentDate = objectData[item]?.commencement_date ? moment(objectData[item]?.commencement_date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY") : "";
          const Repeats = objectData[item]?.repeats || "";
          const NextPayment = lastpayment.length > 0 && lastpayment[0].next_payment_datetime ? lastpayment[0].next_payment_datetime : "";
          const client_signature = objectData[item]?.storage_url && objectData[item]?.client_signature ? `${objectData[item]?.storage_url}/${objectData[item]?.client_signature}` : "";
          const Status = objectData[item]?.status ? objectData[item]?.status : "";

          return (
            <a className="text-decoration-none event-box-pink mb-3">
              {openModalTwo && (
                <>
                  <div className={openModalTwo ? "modal fade show" : "modal fade"} id="add-emailverification" tabIndex="-1" aria-labelledby="addemailverificationModalLabel" style={{ display: openModalTwo ? "block" : "none" }}>
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-body pb-0">
                          <div className="p-3">
                            <h5 className="mb-3 fw-semibold text-dark">{t("Subscription schedule info")}</h5>
                            <p className="fw-semibold mb-2">{t(`This subscription is set to activated on ${CommenceMentDate}`)}</p>
                          </div>
                          <div className="row mt-3 bg-cream p-4 justify-content-center">

                            <div className="col-6">
                              <button className="btn btn-primary w-100" type="button" disabled={loading} onClick={() => {
                                setOpenModalTwo(false);
                              }}>
                                {t("OK")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {openModalTwo && <div className="modal-backdrop show"></div>}
                </>
              )}
              <h6 className="mb-4 color-wine fw-semibold d-flex justify-content-between align-items-start">
                {name}
                <div>
                  <span
                    className="cancel-subscriptionlistview me-3 cursor-pointer cancelled-sub-status"
                    onClick={() => {
                      // setPrintLoader(true);
                      // dispatch(SubscriptionInvoiceApi({ client_subscription_plan_id: objectData[item]?.id })).then((action) => {
                      //   if (action?.meta?.requestStatus === "fulfilled") {
                      //     setPrintLoader(false);
                      //     window.open(`/subscription-invoice`, '_blank');
                      //   } else {
                      //     setPrintLoader(false);
                      //     customToast.error(t("Something went wrong."));
                      //   }
                      // })
                      window.open(`/subscription-invoice/${objectData[item]?.id}/clientsubscription`, '_blank');
                      // navigate(`/subscription-invoice/${objectData[item]?.id}/clientsubscription`)
                    }}
                  >
                    {printLoader && <span className="spinner-border spinner-border-sm"></span>}
                    Print
                  </span>

                  {is_subscription_cancel == 1 ?
                    (
                      <>
                        <span className="cancel-subscriptionlistview me-3 cursor-pointer cancelled-sub-status">
                          Cancelled
                        </span>
                      </>
                    ) : (
                      <span className="cancel-subscriptionlistview cancel-btn-sub me-3 cursor-pointer"
                        onClick={(e) => {
                          setModalClickedData(objectData[item]);
                          setOpenModal(true);
                        }}>
                        Cancel
                      </span>
                    )
                  }
                  {is_subscription_active == 1 ?
                    (
                      <>
                        <span className="active-subscriptionlistview cursor-pointer">
                          Active
                        </span>
                      </>
                    ) : (
                      ""
                    )
                  }
                  {stripe_pause_collection ?
                    (
                      <>
                        <span className="active-subscriptionlistview cursor-pointer">
                          Pause ({stripe_pause_collection})
                        </span>
                      </>
                    ) : (
                      ""
                    )
                  }
                </div>
              </h6>
              {objectData[item] && objectData[item].subscriptionservicesmanage && objectData[item].subscriptionservicesmanage.length > 0 ? (
                <div className="mb-2">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th style={headerCellStyle}>Services Included</th>
                        <th style={tableCellStyle}>Qty</th>
                        <th style={tableCellStyle}>Used</th>
                        <th style={tableCellStyle}>Available</th>
                      </tr>
                    </thead>
                    <tbody>
                      {objectData[item].subscriptionservicesmanage.map((item, i) => (
                        <tr key={item && item.id ? item.id : i} style={{ border: 'none' }}>
                          <td className="text-dark" style={serviceCellStyle}>{item.services.name}</td>
                          <td style={tableCellStyle}>{item.qty ? item.qty : 0}</td>
                          <td style={tableCellStyle}>{item.used_qty ? item.used_qty : 0}</td>
                          <td style={tableCellStyle}>{item.pending_qty ? item.pending_qty : 0}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : ""}
              <div className="subscriptionlistview-date my-2 fw-semibold text-dark">
                Payment History
              </div>
              <>
                {lastpayment.length > 0 ? (
                  lastpayment.map((items) => {
                    // payment_date : "2024-01-31 18:19:13"
                    let payment_date = items && items.payment_date ? moment(items.payment_date, "YYYY-MM-DD HH:mm:ss").format('DD/MM/YYYY') : '';
                    let amount_paid = items && items.amount ? items.amount : 0;
                    let Status = items && (items.status == 'Schedule' ? 'Scheduled' : items.status);
                    let badgeColor = items && items.status && items.status == "Paid" ? "paid" : "unpaid";
                    return (
                      <h6 className="mb-1 subscriptionlistview-date d-flex justify-content-between align-items-baseline">
                        <div>
                          <span className="subscriptionlistview-offer-time">{payment_date}</span>
                        </div>
                        <div>
                          <span className="subscriptionlistview-offer-time">${amount_paid}</span>
                        </div>
                        <div>
                          <span className={`badge ${badgeColor} text-capitalize`}>{Status ? Status : "-"}</span>
                        </div>
                      </h6>
                    )
                  })
                ) : ""}
              </>
            </a>
          );
        })}
    </div>
  );
};

export default SubscriptionListview;