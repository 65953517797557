import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { ucfirst } from "helpers/Functions";
import Moment from "react-moment";
import config from "../../../../config";
import { appointmentDetailApi, CalendarEditFormSetActivebtnState, ClientInvoiceSaleCartApi, EditAppointmentForm, GetStripeTokenAppointment } from "store/slices/appointmentSlice";
import { openPastInvoiceDrawer, OpenRefundInvoice, PastInvoiceData, RefundInvoiceData, saleDetailApi } from "store/slices/clientappointmentinvoiceSlice";
import { serviceOptions } from "store/slices/serviceSlice";
import { staffOptions } from "store/slices/staffSlice";
import customToast from "component/Toastr";
import { GetClientDetailTabInvoiceData, OpenClientInvoiceDetails } from "store/slices/clientinvoiceSlice";
import MoonLoader from "react-spinners/MoonLoader";

const AppointmentListview = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const views = props.view;
  // const currentUser = props.role_id;
  // const access = props.access;
  // const view = useSelector((state) => state.client.isView);
  const objectData = views && views.data ? views.data : views;
  const [loader, setLoader] = useState({});

  return (
    <>
      {objectData &&
        Object.keys(objectData).map((item, index) => {
          let id = objectData[item].id;
          let service_id = objectData[item].service_id;
          let start_datetime = objectData[item].start_datetime;
          let end_datetime = objectData[item].end_datetime;
          let status = objectData[item].status;
          // let duration = objectData[item].duration;
          // let repeats = objectData[item].repeats;
          // let booking_notes = objectData[item].booking_notes;
          // let cancellation_reason = objectData[item].cancellation_reason;
          // let reschedule = objectData[item].reschedule;
          // let reschedule_at = objectData[item].reschedule_at;
          let service = objectData[item].service;
          let staff = objectData[item].staff;
          let service_name = service && service.name;
          let staff_first_name = staff && staff.first_name;
          let staff_last_name = staff && staff.last_name;
          let previousstates = objectData[item].sale;
          let paymentsale = previousstates && previousstates.status;
          let sale_id = objectData[item] && objectData[item].sale_id ? objectData[item].sale_id : "";
          let salon_id = objectData[item] && objectData[item].salon_id ? objectData[item].salon_id : "";
          let client_id = objectData[item] && objectData[item].client_id ? objectData[item].client_id : "";
          const is_active = objectData[item]?.staff?.is_active == 1 ? true : false;

          let previousStatus = status;
          if (item > 0) {
            previousStatus = objectData[item - 1].status;
          }
          return (
            <div key={item && item.id ? item.id : index} className="d-flex flex-column" id="invoice-drawer">
              <h4 className="mb-3">{item > 0 && previousStatus === status ? "" : status}</h4>
              <div className="client-invoice-position">
                <div
                  className={(status === "Scheduled" && "cursor-pointer") + "text-decoration-none event-box"}
                  onClick={() => {
                    if (status === "Scheduled" && is_active) {
                      dispatch(appointmentDetailApi({ id, client_id })).then((action) => {
                        if (action.meta.requestStatus === "fulfilled") {
                          dispatch(EditAppointmentForm("open"));
                          dispatch(serviceOptions({ option: { valueField: "id", labelField: "name" } }));
                          dispatch(staffOptions({ option: { valueField: "users.id", labelField: "CONCAT(users.last_name,' ',users.first_name)" }, service_id: service_id }));
                        } else if (action.meta.requestStatus === "rejected") {
                          if (action.payload.status === 422) {
                            let error = action.payload;
                            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                            sweatalert({ title: message.errors.document[0], text: message.errors.document, icon: "error" });
                          }
                        }
                      });
                    }
                  }}
                >
                  <div
                    className={(status === "Confirmed" && "cursor-pointer") + "  client-invoice-position text-decoration-none"}
                    onClick={() => {
                      if (status === "Confirmed") {
                        dispatch(appointmentDetailApi({ id, client_id })).then((action) => {
                          if (action.meta.requestStatus === "fulfilled") {
                            dispatch(GetStripeTokenAppointment(""));
                            dispatch(CalendarEditFormSetActivebtnState(""));
                            dispatch(serviceOptions({ option: { valueField: "id", labelField: "name" } }));
                            dispatch(staffOptions({ option: { valueField: "users.id", labelField: "CONCAT(users.last_name,' ',users.first_name)" }, service_id: service_id }));
                            dispatch(EditAppointmentForm("open"));
                          } else if (action.meta.requestStatus === "rejected") {
                            if (action.payload.status === 422) {
                              let error = action.payload;
                              const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                              sweatalert({ title: message.errors.document[0], text: message.errors.document, icon: "error" });
                            }
                          }
                        });
                      }
                    }}
                  >
                    <h6 className="mb-1 color-wine fw-semibold d-flex align-items-start">
                      <Moment format="dddd, DD MMMM YYYY">{start_datetime}</Moment>
                    </h6>
                    <h6 className="mb-1">
                      <Moment format="hh:mm A">{start_datetime}</Moment>
                    </h6>
                    <h6 className="mb-0">{t("{{service_name}} with {{staff_name}}", { service_name: service_name, staff_name: ucfirst(staff_first_name + " " + staff_last_name) })}</h6>
                    {/* {objectData[item] && objectData[item].addonservices && objectData[item].addonservices.length > 0 ? (<h6 className="my-2 fw-semibold">{t("addon services")}</h6>) : null} */}
                    {objectData[item] && objectData[item].addonservices ? (
                      objectData[item] && objectData[item].addonservices.map((addon, i) => {
                        let addonservicename = addon.service.name;
                        return (
                          <div key={addon && addon.id ? addon.id : i} className="mb-0">
                            <h6 className="mb-0 d-inline">{t(addonservicename)}</h6>
                            {objectData[item].addonservices && objectData[item].addonservices.length > 1 ? ("") : null}
                          </div>
                        )
                      })
                    ) : null}
                    {/* {objectData[item] && objectData[item].addanotherservices && objectData[item].addanotherservices.length > 0 ? (<h6 className="my-2 fw-semibold">{t("add another services")}</h6>) : null} */}
                    {objectData[item] && objectData[item].addanotherservices ? (
                      objectData[item] && objectData[item].addanotherservices.map((addon, i) => {
                        let addonservicename = addon.service.name;
                        return (
                          <div key={addon && addon.id ? addon.id : i} className="mb-0">
                            <h6 className="mb-0 d-inline">{t(addonservicename)}</h6>
                            {objectData[item].addanotherservices && objectData[item].addanotherservices.length > 1 ? ("") : null}
                          </div>
                        )
                      })
                    ) : null}
                  </div>
                </div>
                {paymentsale && paymentsale === "Paid" ? (
                  <a className="ms-auto cursor-pointer invoice-link past-invoice-images" onClick={() => {
                    setLoader((prevLoader) => ({ ...prevLoader, [index]: true }));
                    dispatch(ClientInvoiceSaleCartApi({ sale_id: sale_id, client_id: client_id, salon_id: salon_id })).then((action) => {
                      if (action.meta.requestStatus == "fulfilled" && action.payload) {
                        dispatch(GetClientDetailTabInvoiceData(action.payload ? action.payload : ""));
                        dispatch(OpenClientInvoiceDetails("open"));
                        setLoader((prevLoader) => ({ ...prevLoader, [index]: false }));
                        // dispatch(RefundInvoiceData(objectData[item]));
                        // dispatch(PastInvoiceData(objectData[item]))
                        // dispatch(openPastInvoiceDrawer("open"));
                      } else {
                        customToast.error("something went wrong");
                        setLoader((prevLoader) => ({ ...prevLoader, [index]: false }));
                      }
                    })
                  }}>
                    {loader[index] ? (
                      <MoonLoader color="#8c1c4d" size="22px" />
                    ) : (
                      <img src={config.imagepath + "past.png"} alt="" />
                    )}
                  </a>
                ) : (
                  <>
                    {(paymentsale && paymentsale === "Partial" || objectData[item] && (objectData[item]?.payment?.length > 0 || objectData[item]?.sale && objectData[item]?.sale?.refund?.length > 0)) ? (
                      <a className="ms-auto cursor-pointer invoice-link past-invoice-images" onClick={() => {
                        dispatch(ClientInvoiceSaleCartApi({ sale_id: sale_id, client_id: client_id, salon_id: sale_id })).then((action) => {
                          dispatch(RefundInvoiceData(objectData[item]));
                          dispatch(OpenRefundInvoice("open"));
                        })
                      }}>
                        <img src={config.imagepath + "past.png"} alt="" />
                      </a>
                    ) : ""}
                  </>
                )}
              </div>
            </div>
          );
        })}

      {/* <div className="mb-lg-4">
        <h4 className="mb-3">Upcoming</h4>
        <a className="text-decoration-none event-box" id="addappoinment-drawer-link">
          <h6 className="mb-1 color-wine fw-semibold">Monday, August 17th 2021</h6>
          <h6 className="mb-1">10:00am</h6>
          <h6 className="mb-0">Haircut & Blow Dry with Amanda</h6>
        </a>
      </div>
      <div className="mb-lg-4">
        <h4 className="mb-3">Past </h4>
        <div className="event-box">
          <h6 className="mb-0 color-wine fw-semibold d-flex align-items-start">
            Monday, August 17th 2021
            <a className="ms-auto invoice-link">
              <img src="assets/images/past.png" alt="" />
            </a>
          </h6>
          <h6 className="mb-1">10:00am</h6>
          <h6 className="mb-0">Haircut & Blow Dry with Amanda</h6>
        </div>
      </div> */}
    </>
  );
};

AppointmentListview.propTypes = {
  view: PropTypes.oneOfType([PropTypes.node, PropTypes.array, PropTypes.object]),
  access: PropTypes.oneOfType([PropTypes.node, PropTypes.array, PropTypes.object]),
  role_id: PropTypes.oneOfType([PropTypes.node, PropTypes.array, PropTypes.object]),
};

export default AppointmentListview;
