import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import staffApiController from "../../services/staff.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";

export const staffStoreApi = createAsyncThunk("staff/create", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await staffApiController
      .create(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "create"))
      .catch((error) => HandleError(thunkAPI, error, "create"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const staffUpdateApi = createAsyncThunk("staff/update", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await staffApiController
      .update(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "update"))
      .catch((error) => HandleError(thunkAPI, error, "update"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const staffGridViewApi = createAsyncThunk("staff/gridview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await staffApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "gridview"))
      .catch((error) => HandleError(thunkAPI, error, "gridview"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const staffCountApi = createAsyncThunk("staff/counts", async (formValues, thunkAPI) => {
  try {
    const resposedata = await staffApiController
      .staffcounts(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "gridview"))
      .catch((error) => HandleError(thunkAPI, error, "gridview"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const staffDetailApi = createAsyncThunk("staff/detail", async (formValues, thunkAPI) => {
  try {
    const resposedata = await staffApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "detail"))
      .catch((error) => HandleError(thunkAPI, error, "detail"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const staffDeleteApi = createAsyncThunk("staff/delete", async (formValues, thunkAPI) => {
  try {
    const resposedata = await staffApiController
      .deleted(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "delete"))
      .catch((error) => HandleError(thunkAPI, error, "delete"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const staffSuggetionListApi = createAsyncThunk("staff/suggetionlist", async (formValues, thunkAPI) => {
  try {
    const resposedata = await staffApiController
      .suggetionlist(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "suggetionlist"))
      .catch((error) => HandleError(thunkAPI, error, "suggetionlist"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const addonservices = createAsyncThunk("staff/addonservices", async (formValues, thunkAPI) => {
  try {
    const resposedata = await staffApiController
      .addonservices(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "addonservices"))
      .catch((error) => HandleError(thunkAPI, error, "addonservices"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const staffOptions = createAsyncThunk("staff/staffOptions", async (formValues, thunkAPI) => {
  try {
    const resposedata = await staffApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "staffOptions"))
      .catch((error) => HandleError(thunkAPI, error, "staffOptions"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const StaffOptionsAutofillApi = createAsyncThunk("staff/StaffOptionsAutofillApi", async (formValues, thunkAPI) => {
  try {
    const resposedata = await staffApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "StaffOptionsAutofillApi"))
      .catch((error) => HandleError(thunkAPI, error, "StaffOptionsAutofillApi"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const staffOptionsDropdown = createAsyncThunk("staff/staffOptionsDropdown", async (formValues, thunkAPI) => {
  try {
    const resposedata = await staffApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "staffOptionsDropdown"))
      .catch((error) => HandleError(thunkAPI, error, "staffOptionsDropdown"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const staffOptionsDropdownSingle = createAsyncThunk("staff/staffOptionsDropdownsingle", async (formValues, thunkAPI) => {
  try {
    const resposedata = await staffApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "staffOptionsDropdownsingle"))
      .catch((error) => HandleError(thunkAPI, error, "staffOptionsDropdownsingle"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const StaffAvailableTimeApi = createAsyncThunk("staff/StaffAvailableTime", async (formValues, thunkAPI) => {
  try {
    const resposedata = await staffApiController
      .staffavailabletime(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "StaffAvailableTime"))
      .catch((error) => HandleError(thunkAPI, error, "StaffAvailableTime"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const StaffAvailableStartBusyTimeApi = createAsyncThunk("staff/StaffAvailableStartBusyTimeApi", async (formValues, thunkAPI) => {
  try {
    const resposedata = await staffApiController
      .staffbusytime(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "StaffAvailableStartBusyTimeApi"))
      .catch((error) => HandleError(thunkAPI, error, "StaffAvailableStartBusyTimeApi"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const StaffAvailableEndBusyTimeApi = createAsyncThunk("staff/StaffAvailableEndBusyTimeApi", async (formValues, thunkAPI) => {
  try {
    const resposedata = await staffApiController
      .staffbusytime(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "StaffAvailableEndBusyTimeApi"))
      .catch((error) => HandleError(thunkAPI, error, "StaffAvailableEndBusyTimeApi"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const StaffworkinghoursApi = createAsyncThunk("staff/staffworkinghours", async (formValues, thunkAPI) => {
  try {
    const resposedata = await staffApiController
      .staffworkinghours(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "staffworkinghours"))
      .catch((error) => HandleError(thunkAPI, error, "staffworkinghours"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const StaffAwayApi = createAsyncThunk("staff/staffawayapi", async (formValues, thunkAPI) => {
  try {
    const resposedata = await staffApiController
      .staffawayapi(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "staffawayapi"))
      .catch((error) => HandleError(thunkAPI, error, "staffawayapi"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const StaffAccessListViewApi = createAsyncThunk("staff/accesslist", async (formValues, thunkAPI) => {
  try {
    const resposedata = await staffApiController
      .staffaccesslistview(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "staffaccesslistapi"))
      .catch((error) => HandleError(thunkAPI, error, "staffaccesslistapi"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const StaffAccessSubmitApi = createAsyncThunk("staff/accesssubmit", async (formValues, thunkAPI) => {
  try {
    const resposedata = await staffApiController
      .staffaccesscreate(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "staffaccessapi"))
      .catch((error) => HandleError(thunkAPI, error, "staffaccessapi"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const RosterStaffApi = createAsyncThunk("staff/rosterstaff", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await staffApiController
      .rosterstaff(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "rosterstaff"))
      .catch((error) => HandleError(thunkAPI, error, "rosterstaff"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const CalendarResourceAndStaffOptionApi = createAsyncThunk("appointment/CalendarResourceAndStaffOption", async (formValues, thunkAPI) => {
  try {
    const resposedata = await staffApiController
      .calendarresourceandstaff(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "CalendarResourceAndStaffOption"))
      .catch((error) => HandleError(thunkAPI, error, "CalendarResourceAndStaffOption"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const staffDropDownOptionApi = createAsyncThunk("staff/staffDropDownOption", async (formValues, thunkAPI) => {
  try {
    const resposedata = await staffApiController
      .view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "staffDropDownOption"))
      .catch((error) => HandleError(thunkAPI, error, "staffDropDownOption"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isOpenedAddForm: "",
  isOpenedEditForm: "",
  isOpenedDetailModal: "",
  isGridView: [],
  isSuggetionListView: [],
  isDetailData: "",
  isTabView: "staff",
  isSort: "",
  isSearchList: "",
  isSearchName: "",
  isAddonServices: [],
  isStaffOption: [],
  isStaffOptionsAutofill: [],
  isStaffOptionDropdown: [],
  isStaffOptionDropdownSingle: [],
  isStaffAvailableTime: [],
  isStaffAvailableStartBusyTime: [],
  isStaffAvailableEndBusyTime: [],
  isStaffworkinghours: [],
  isStaffAway: [],
  isStaffMaxLimits: "",
  isStaffPermissionModal: "",
  isStaffAccessList: [],
  isCalendarResourceAndStaffOption: [],
  isStaffEditDetailsForPermission: "",
  isStaffAccessSubmit: "",
  isRosterStaff: "",
  isTotalNumberOfStaff : 0,
  isStaffDropDownOption:[],
  isOpenStaffAppointmentShiftModal:"",
  isStaffAppointmentShiftModalData:"",
  isApiStatus: {
    StaffAvailableTimeApi: "",
    StaffOptionsAutofillApi: "",
    staffStoreApi: "",
    staffUpdateApi: "",
    staffGridViewApi: "",
    staffSuggetionListApi: "",
    staffDetailApi: "",
    staffOptions: "",
    staffOptionsDropdown: "",
    staffOptionsDropdownSingle: "",
    addonservices: "",
    StaffAvailableTime: "",
    StaffworkinghoursApi: "",
    StaffAwayApi: "",
    StaffAccessSubmitApi: "",
    StaffAccessListViewApi: "",
    RosterStaffApi: "",
    StaffAvailableStartBusyTimeApi: "",
    StaffAvailableEndBusyTimeApi: "",
    CalendarResourceAndStaffOptionApi: "",
    staffCountApi:"",
    staffDropDownOptionApi:""
  },
};

const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    reset: () => initialState,
    staffTabView: (state, action) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "";
      state.isOpenedDetailModal = "";
      state.isTabView = action.payload;
    },
    openAddStaffForm: (state = initialState) => {
      state.isOpenedAddForm = "open";
      state.isOpenedEditForm = "";
      state.isOpenedDetailModal = "";
    },
    closeAddStaffForm: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "";
      state.isOpenedDetailModal = "";
    },
    openEditStaffForm: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "open";
      state.isOpenedDetailModal = "";
    },
    closeEditStaffForm: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "";
      state.isOpenedDetailModal = "";
    },
    openStaffDetailModal: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "";
      state.isOpenedDetailModal = "open";
    },
    closeStaffDetailModal: (state = initialState) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "";
      state.isOpenedDetailModal = "";
    },
    staffSort: (state, action) => {
      // let sort = state.isSort ? state.isSort : {};
      // state.isSort = Object.assign(sort, action.payload);
      state.isSort = action.payload;
    },
    staffSortRemove: (state) => {
      state.isSort = "";
    },
    openStaffSearchList: (state) => {
      state.isSearchList = "open";
    },
    closeStaffSearchList: (state) => {
      state.isSearchList = "";
    },
    staffSearchName: (state, action) => {
      state.isSearchName = action.payload;
    },
    staffUpgradePackage: (state, action) => {
      state.isStaffMaxLimits = action.payload;
    },
    staffPermissionModal: (state, action) => {
      state.isStaffPermissionModal = action.payload;
    },
    StaffEditDetailsForPermission: (state, action) => {
      state.isStaffEditDetailsForPermission = action.payload;
    },
    OpenStaffAppointmentShiftModal: (state, action) => {
      state.isOpenStaffAppointmentShiftModal = action.payload;
    },
    StaffAppointmentShiftModalData: (state, action) => {
      state.isStaffAppointmentShiftModalData = action.payload;
    },
    addonserviceAction: (state, action) => {
      const { id, ...changes } = action.payload;
      const existingData = state.isAddonServices.find((event) => event.id === id);
      if (existingData) {
        Object.keys(changes).map((keyName) => {
          existingData[keyName] = changes[keyName];
        });
      }
      // state.isAddonServices = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(staffDropDownOptionApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, staffDropDownOptionApi: "loading" };
    })
    .addCase(staffDropDownOptionApi.fulfilled, (state, action) => {
      state.isStaffDropDownOption = action.payload;
      state.isApiStatus = { ...state.isApiStatus, staffDropDownOptionApi: "succeeded" };
    })
    .addCase(staffDropDownOptionApi.rejected, (state) => {
      state.isStaffDropDownOption = [];
      state.isApiStatus = { ...state.isApiStatus, staffDropDownOptionApi: "failed" };
    })
    .addCase(staffCountApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, staffCountApi: "loading" };
    })
    .addCase(staffCountApi.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, staffCountApi: "succeeded" };
      if(action.payload.staff_count){
        state.isTotalNumberOfStaff = action.payload.staff_count;
      }
    })
    .addCase(staffCountApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, staffCountApi: "failed" };
      state.isTotalNumberOfStaff = "";
    })

    .addCase(staffStoreApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, staffStoreApi: "loading" };
    })
    .addCase(staffStoreApi.fulfilled, (state, action) => {
      if (state.isGridView && state.isGridView.data) {
        state.isGridView.data = [action.payload, ...state.isGridView.data];
      } else {
        state.isGridView = { data: [action.payload] };
      }
      state.isApiStatus = { ...state.isApiStatus, staffStoreApi: "succeeded" };
    })
    .addCase(staffStoreApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, staffStoreApi: "failed" };
    })
    .addCase(staffUpdateApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, staffUpdateApi: "loading" };
    })
    .addCase(staffUpdateApi.fulfilled, (state, action) => {
      const { id, ...changes } = action.payload;
      const existingData = state.isGridView && state.isGridView.data && state.isGridView.data.find((event) => event.id === id);
      if (existingData) {
        Object.keys(changes).map((keyName) => {
          existingData[keyName] = changes[keyName];
        });
      }
      state.isApiStatus = { ...state.isApiStatus, staffUpdateApi: "succeeded" };
    })
    .addCase(staffUpdateApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, staffUpdateApi: "failed" };
    })
    .addCase(staffGridViewApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, staffGridViewApi: "loading" };
    })
    .addCase(staffGridViewApi.fulfilled, (state, action) => {
      let old_current_page = state.isGridView.current_page ? state.isGridView.current_page : "";
      let new_current_page = action.payload.current_page ? action.payload.current_page : "";
      let viewdata = state.isGridView && state.isGridView.data;
      let newviewdata = action.payload && action.payload.data;
      state.isGridView = action.payload;
      if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
        viewdata && newviewdata ? (state.isGridView.data = [...viewdata, ...newviewdata]) : action.payload;
      }
      state.isApiStatus = { ...state.isApiStatus, staffGridViewApi: "succeeded" };
      state.isGridView = action.payload;
    })
    .addCase(staffGridViewApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, staffGridViewApi: "failed" };
      state.isGridView = [];
    })
    .addCase(staffSuggetionListApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, staffSuggetionListApi: "loading" };
    })
    .addCase(staffSuggetionListApi.fulfilled, (state, action) => {
      let old_current_page = state.isSuggetionListView.current_page ? state.isSuggetionListView.current_page : "";
      let new_current_page = action.payload.current_page ? action.payload.current_page : "";
      let viewdata = state.isSuggetionListView && state.isSuggetionListView.data;
      let newviewdata = action.payload && action.payload.data;
      state.isSuggetionListView = action.payload;
      if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
        viewdata && newviewdata ? (state.isSuggetionListView.data = [...viewdata, ...newviewdata]) : action.payload;
      }
      state.isApiStatus = { ...state.isApiStatus, staffSuggetionListApi: "succeeded" };
      state.isSuggetionListView = action.payload;
    })
    .addCase(staffSuggetionListApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, staffSuggetionListApi: "failed" };
      state.isSuggetionListView = [];
    })
    .addCase(staffDetailApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, staffDetailApi: "loading" };
    })
    .addCase(staffDetailApi.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, staffDetailApi: "succeeded" };
      state.isDetailData = action.payload;
    })
    .addCase(staffDetailApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, staffDetailApi: "failed" };
      state.isDetailData = "";
    })
    .addCase(staffDeleteApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, staffDeleteApi: "loading" };
    })
    .addCase(staffDeleteApi.fulfilled, (state, action) => {
      const { id } = action.payload;
      state.isApiStatus = { ...state.isApiStatus, staffDeleteApi: "succeeded" };
      state.isGridView.data = state.isGridView.data ? state.isGridView.data.filter((item) => item.id != id) : state.isGridView.filter((item) => item.id != id);
    })
    .addCase(staffDeleteApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, staffDeleteApi: "failed" };
    })
    .addCase(addonservices.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, addonservices: "loading" };
    })
    .addCase(addonservices.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, addonservices: "succeeded" };
      state.isAddonServices = action.payload;
    })
    .addCase(addonservices.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, addonservices: "failed" };
      state.isAddonServices = [];
    })
    .addCase(staffOptions.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, staffOptions: "loading" };
    })
    .addCase(staffOptions.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, staffOptions: "succeeded" };
      state.isStaffOption = action.payload;
    })
    .addCase(staffOptions.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, staffOptions: "failed" };
      state.isStaffOption = [];
    })
    .addCase(StaffOptionsAutofillApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, StaffOptionsAutofillApi: "loading" };
    })
    .addCase(StaffOptionsAutofillApi.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, StaffOptionsAutofillApi: "succeeded" };
      state.isStaffOptionsAutofill = action.payload;
    })
    .addCase(StaffOptionsAutofillApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, StaffOptionsAutofillApi: "failed" };
      state.isStaffOptionsAutofill = [];
    })
    .addCase(staffOptionsDropdown.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, staffOptionsDropdown: "loading" };
    })
    .addCase(staffOptionsDropdown.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, staffOptionsDropdown: "succeeded" };
      state.isStaffOptionDropdown = action.payload;
    })
    .addCase(staffOptionsDropdown.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, staffOptionsDropdown: "failed" };
      state.isStaffOptionDropdown = [];
    })
    .addCase(staffOptionsDropdownSingle.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, staffOptionsDropdownSingle: "loading" };
    })
    .addCase(staffOptionsDropdownSingle.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, staffOptionsDropdownSingle: "succeeded" };
      state.isStaffOptionDropdownSingle = action.payload;
    })
    .addCase(staffOptionsDropdownSingle.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, staffOptionsDropdownSingle: "failed" };
      state.isStaffOptionDropdownSingle = [];
    })
    .addCase(StaffAvailableTimeApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, StaffAvailableTimeApi: "loading" };
    })
    .addCase(StaffAvailableTimeApi.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, StaffAvailableTimeApi: "succeeded" };
      if (action && action.meta && action.meta.arg && action.meta.arg.selectedOptionType && action.meta.arg.selectedOptionType === "resource_item_id") {
        // Handle special case if necessary
      } else {
        state.isStaffAvailableTime = action.payload;
      }
    })
    .addCase(StaffAvailableTimeApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, StaffAvailableTimeApi: "failed" };
      state.isStaffAvailableTime = "";
    })

    .addCase(StaffAvailableStartBusyTimeApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, StaffAvailableStartBusyTimeApi: "loading" };
    })
    .addCase(StaffAvailableStartBusyTimeApi.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, StaffAvailableStartBusyTimeApi: "succeeded" };
      state.isStaffAvailableStartBusyTime = action.payload;
    })
    .addCase(StaffAvailableStartBusyTimeApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, StaffAvailableStartBusyTimeApi: "failed" };
      state.isStaffAvailableStartBusyTime = "";
    })
    .addCase(StaffAvailableEndBusyTimeApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, StaffAvailableEndBusyTimeApi: "loading" };
    })
    .addCase(StaffAvailableEndBusyTimeApi.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, StaffAvailableEndBusyTimeApi: "succeeded" };
      state.isStaffAvailableEndBusyTime = action.payload;
    })
    .addCase(StaffAvailableEndBusyTimeApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, StaffAvailableEndBusyTimeApi: "failed" };
      state.isStaffAvailableEndBusyTime = "";
    })
    .addCase(StaffworkinghoursApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, StaffworkinghoursApi: "loading" };
    })
    .addCase(StaffworkinghoursApi.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, StaffworkinghoursApi: "succeeded" };
      state.isStaffworkinghours = action.payload;
    })
    .addCase(StaffworkinghoursApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, StaffworkinghoursApi: "failed" };
      state.isStaffworkinghours = "";
    })
    .addCase(StaffAwayApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, StaffAwayApi: "loading" };
    })
    .addCase(StaffAwayApi.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, StaffAwayApi: "succeeded" };
      state.isStaffAway = action.payload;
    })
    .addCase(StaffAwayApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, StaffAwayApi: "failed" };
      state.isStaffAway = "";
    })
    .addCase(StaffAccessSubmitApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, StaffAccessSubmitApi: "loading" };
    })
    .addCase(StaffAccessSubmitApi.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, StaffAccessSubmitApi: "succeeded" };
      state.isStaffAccessSubmit = action.payload;
    })
    .addCase(StaffAccessSubmitApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, StaffAccessSubmitApi: "failed" };
      state.isStaffAccessSubmit = "";
    })
    .addCase(StaffAccessListViewApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, StaffAccessListViewApi: "loading" };
    })
    .addCase(StaffAccessListViewApi.fulfilled, (state, action) => {
      let old_current_page = state.isStaffAccessList.current_page ? state.isStaffAccessList.current_page : "";
      let new_current_page = action.payload.current_page ? action.payload.current_page : "";
      let viewdata = state.isStaffAccessList && state.isStaffAccessList.data;
      let newviewdata = action.payload && action.payload.data;
      state.isStaffAccessList = action.payload;
      if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page != new_current_page) {
        viewdata && newviewdata ? (state.isStaffAccessList.data = [...viewdata, ...newviewdata]) : action.payload;
      }
      state.isApiStatus = { ...state.isApiStatus, StaffAccessListViewApi: "succeeded" };
      state.isStaffAccessList = action.payload;
    })
    .addCase(StaffAccessListViewApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, StaffAccessListViewApi: "failed" };
      state.isStaffAccessList = [];
    })
    .addCase(RosterStaffApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, RosterStaffApi: "loading" };
    })
    .addCase(RosterStaffApi.fulfilled, (state, action) => {
      state.isApiStatus = { ...state.isApiStatus, RosterStaffApi: "succeeded" };
      state.isRosterStaff = action.payload;
    })
    .addCase(RosterStaffApi.rejected, (state) => {
      state.isApiStatus = { ...state.isApiStatus, RosterStaffApi: "failed" };
    })
    .addCase(CalendarResourceAndStaffOptionApi.pending, (state) => {
      state.isApiStatus = { ...state.isApiStatus, CalendarResourceAndStaffOptionApi: "loading" };
    })
    .addCase(CalendarResourceAndStaffOptionApi.fulfilled, (state, action) => {
      state.isCalendarResourceAndStaffOption = action.payload;
      state.isApiStatus = { ...state.isApiStatus, CalendarResourceAndStaffOptionApi: "succeeded" };
    })
    .addCase(CalendarResourceAndStaffOptionApi.rejected, (state) => {
      state.isCalendarResourceAndStaffOption = [];
      state.isApiStatus = { ...state.isApiStatus, CalendarResourceAndStaffOptionApi: "failed" };
    });
  },
});
// Action creators are generated for each case reducer function
export const { reset, staffTabView, openAddStaffForm, closeAddStaffForm, openEditStaffForm, closeEditStaffForm, staffTabGridView, openStaffDetailModal, closeStaffDetailModal, staffDetailTab, staffSort, staffSortRemove, openStaffSearchList, closeStaffSearchList, staffSearchName, addonserviceAction, staffUpgradePackage, staffPermissionModal, StaffEditDetailsForPermission,StaffAppointmentShiftModalData,OpenStaffAppointmentShiftModal } = staffSlice.actions;
export default staffSlice.reducer;
