import React, { useEffect, useState } from "react";
import { SalonModule } from "pages";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { PackageFeatureViewApi, PackageViewApi, PackagePaymentsData, PackagePaymentsDataName, PackagePaymentModal, MaxStaffAlert, PackageKeyFeaturesApi, PackageInvoiceApi } from "store/slices/packageSlice";
import { SelectField } from "component/form/Field";
import config from "../../config";
import FeatureList from "./List/FeatureList";
import { useNavigate } from "react-router";
import PackagePayment from "./Payments";
import { staffCountApi, staffGridViewApi } from "store/slices/staffSlice";
import customToast from "component/Toastr";
import BillingDetailsEditForm from "pages/setting/Form/BillingDetailsEditForm";
import { ComingSoonPopUpAlerts, ComingSoonPopUpAlertsTextsData } from "store/slices/commonSlice";
import ComingSoonAlertPopUps from "component/ComingSoonAlertPopUps";
import "assets/css/CardSectionStyles.css";
import FadeLoader from "react-spinners/FadeLoader";
import BarLoader from "react-spinners/BarLoader";

const Package = () => {
  SalonModule();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeloader, setActiveloader] = useState({});
  const [maxStaffAlerts, setMaxStaffAlerts] = useState(false);
  const auth = useSelector((state) => state.auth);
  const currentUser = auth.user;
  const role_id = currentUser && currentUser.role_id;
  const access = useSelector((state) => state.salonmodule.isAccess);
  const [cardFeatures, setCardFeatures] = useState(false);
  const isPackageView = useSelector((state) => state.package.isPackageView);
  const isPackageFeatureView = useSelector((state) => state.package.isPackageFeatureView);
  const isPackageModal = useSelector((state) => state.package.isPackageModal);
  const freetrialText = useSelector((state) => state.package.isFreeTrialUpgradePackageTexts);
  const staffUpgradePackageTexts = useSelector((state) => state.package.isstaffUpgradePackageTexts);
  const keyFeaturesList = useSelector((state) => state.package.isPackageKeyFeaturesList);
  const packageApiStatus = useSelector((state) => state.package.isApiStatus);
  const isopenBillingDetailEditForm = useSelector((state) => state.packageplan.isopenBillingDetailEditForm);
  // const isMaxStaffAlert = useSelector((state) => state.package.isMaxStaffAlert);

  const isActivePlan = useSelector((state) => state.salon.isActivePlan);
  const GridView = useSelector((state) => state.staff.isGridView);
  const isTotalNumberOfStaff = useSelector((state) => state.staff.isTotalNumberOfStaff);
  const isComingSoonPopUpAlerts = useSelector((state) => state.common.isComingSoonPopUpAlerts)
  // console.log('isActivePlan',packageApiStatus);
  const GridViewtotallength = GridView;
  let max_staff_member_length = 1;
  if (isTotalNumberOfStaff > 0 && isTotalNumberOfStaff <= 1) {
    max_staff_member_length = 1;
  } else if (isTotalNumberOfStaff > 1 && isTotalNumberOfStaff <= 2) {
    max_staff_member_length = 2;
  } else if (isTotalNumberOfStaff > 2 && isTotalNumberOfStaff <= 5) {
    max_staff_member_length = 5;
  } else if (isTotalNumberOfStaff > 5 && isTotalNumberOfStaff <= 10) {
    max_staff_member_length = 10;
  } else if (isTotalNumberOfStaff > 10 && isTotalNumberOfStaff >= 11) {
    max_staff_member_length = null;
  }

  const [selectedStaff, setSelectedStaff] = useState(max_staff_member_length);

  useEffect(() => {
    // Update the selected value based on staffGridView length during the initial page load
    setSelectedStaff(max_staff_member_length);
  }, [max_staff_member_length,isTotalNumberOfStaff]);

  useEffect(() => {
    dispatch(PackageViewApi({ max_staff_member: max_staff_member_length }));
    dispatch(PackageFeatureViewApi());
    dispatch(PackagePaymentModal(""));
    // dispatch(staffGridViewApi({ maxstaffAlert: "false" }));
    dispatch(staffCountApi());
    dispatch(PackageKeyFeaturesApi());
  }, [max_staff_member_length,isTotalNumberOfStaff]);

  const stafftypeOptionsData = [
    { value: "1", label: "1 Staff Member" },
    { value: "2", label: "1-2 Staff Member" },
    { value: "5", label: "3-5 Staff Members" },
    { value: "10", label: "6-10 Staff Members" },
    { value: "", label: "11+ Staff Members" },
  ];

  const packageTextChanges = () => {
    if (freetrialText.isfreeTrialTitleTexts.length > 0 && freetrialText.isfreeTrialdescriptionTexts.length > 0) {
      return (
        <div className="active-account-title text-center">
          <h2>{t(freetrialText.isfreeTrialTitleTexts)}</h2>
          <p>{t(freetrialText.isfreeTrialdescriptionTexts)}</p>
        </div>
      );
    } else if (staffUpgradePackageTexts.isstaffTitleTexts.length > 0 && staffUpgradePackageTexts.isstaffdescriptionTexts.length > 0) {
      return (
        <div className="active-account-title text-center">
          <h2>{t(staffUpgradePackageTexts.isstaffTitleTexts)}</h2>
          <p>{t(staffUpgradePackageTexts.isstaffdescriptionTexts)}</p>
        </div>
      );
    } else {
      return (
        <div className="active-account-title text-center">
          <h2>{t("Your free trial has expired - pick a plan to continue.")}</h2>
          <p>{t("All of your work is saved and you can reopen your account by picking a plan below.")}</p>
        </div>
      );
    }
  };

  useEffect(() => {
    if (isPackageView && isPackageView.length > 0) {
      let hasMaxStaffAlert = false; // Initialize the flag
      isPackageView.forEach(item => {
        let packagemanage = item.packagemanage && item.packagemanage.length === 1 && item.packagemanage[0];
        if (packagemanage) {
          let max_staff_member = packagemanage.max_staff_member;
          if ((max_staff_member && isTotalNumberOfStaff <= max_staff_member) || max_staff_member === null) {
            hasMaxStaffAlert = true; // Set the flag if condition met
          }
        }
      });
      setMaxStaffAlerts(hasMaxStaffAlert); // Update state once outside the loop
    }
  }, [isPackageView, GridViewtotallength]);

  return (
    <>
      <div className="page-content dashboard-content">
        <div className="package">
          <section className="active-accoun-sec bg-pink">
            <div className="container">
              <div className="row">
                <div className="active-account-title text-center">{packageTextChanges()}</div>
                <div className="active-plan-wrapper">
                  <div className={`plan-sele ${maxStaffAlerts ? "mb-3" : ""}`}>
                    <div className="plan-lable">
                      <label className="price-dropdown-title">Show prices for</label>
                    </div>
                    <div className="col-md-2">
                      <select
                        name="max_staff_member"
                        id="max_staff_member"
                        className="form-control"
                        onChange={(e) => {
                          // Update the selected value when the user selects a different option
                          setSelectedStaff(e.target.value);
                          // Dispatch the API call with the selected value
                          dispatch(PackageViewApi({ max_staff_member: e.target.value }));
                        }}
                        value={selectedStaff ? selectedStaff : ""} // Use the selected value from state
                      >
                        {stafftypeOptionsData &&
                          stafftypeOptionsData.length > 0 &&
                          stafftypeOptionsData.map((item, i) => {
                            let value = item.value;
                            let label = item.label;
                            return (
                              <option key={i} value={value}>
                                {label}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    {packageApiStatus && packageApiStatus.PackageViewApi === "loading" && (
                      <FadeLoader color="#8c1c4d" className="d-flex mx-auto my-5" />
                    )}
                  </div>

                  <div className="plan-column-wr">
                    <div className="row justify-content-center">
                      {maxStaffAlerts ? null : (
                        <div className="col-7 col-md-12 mx-auto mt-5">
                          <div className="alert alert-danger" role="alert">
                            {t("You have too many staff for these plans. To downgrade your plan, please remove some of your staff first.")}
                          </div>
                        </div>
                      )}
                      {isPackageView &&
                        isPackageView.length > 0 &&
                        Object.keys(isPackageView).map((item, i) => {
                          let id = isPackageView[item].id;
                          let title = isPackageView[item].name;
                          let short_description = isPackageView[item].short_description;
                          let packagemanage = isPackageView[item].packagemanage && isPackageView[item].packagemanage.length == 1 && isPackageView[item].packagemanage[0];
                          // let packagemanage = false;
                          let max_staff_member = packagemanage.max_staff_member;
                          let package_type_id = packagemanage.package_type_id;
                          let keyFeaturesListNew = keyFeaturesList && keyFeaturesList.length > 0 && keyFeaturesList.filter((fa) => fa.package_type_id == id);

                          // let titleline = title.replace(/\s+/g, "_");
                          if (packagemanage) {
                            // let upgradebtn = false;
                            // if (max_staff_member && GridViewtotallength.length <= max_staff_member) {
                            //   upgradebtn = true;
                            // } else if (max_staff_member == null) {
                            //   upgradebtn = true;
                            // }
                            let btnActivePlan = false;
                            if (isActivePlan && packagemanage && isActivePlan.package_type_id === packagemanage.package_type_id && isActivePlan.package_manage_id === packagemanage.id) {
                              btnActivePlan = true;
                            }

                            // dispatch(MaxStaffAlert(upgradebtn));
                            return (
                              <div key={i} className={`${'col-lg-6 col-md-8'} mb-3`}>
                                <div className="plan-wr" style={{ opacity: (selectedStaff == 1 ? '1' : (package_type_id == 1 && max_staff_member == 1 ? '0.4' : '1')) }}>
                                  <h2>{title}</h2>
                                  <p>{short_description}</p>
                                  <div className="pal-spr">
                                    <h2>${packagemanage.price}</h2>
                                    <sub>{packagemanage.currency + "/" + packagemanage.type}</sub>
                                  </div>
                                  <div className="plan-btn-wr">
                                    {btnActivePlan && currentUser.salon && currentUser.salon.is_trial_plan === 0 ? <a className="btn btn-light-gray rounded fw-bold" >{t('Currently Active')}</a> : <>
                                      {maxStaffAlerts && (
                                        <a
                                          className="btn btn-primary rounded fw-bold"
                                          onClick={() => {
                                            if (activeloader[item] && packageApiStatus && packageApiStatus.PackageInvoiceApi === "loading") {
                                              return;
                                            } else {
                                              setActiveloader((prevLoader) => ({ ...prevLoader, [item]: true }));
                                              if (isPackageView[item] && isPackageView[item].name === "Accelerate") {
                                                setActiveloader((prevLoader) => ({ ...prevLoader, [item]: true }));
                                                dispatch(ComingSoonPopUpAlertsTextsData({ title: "This plan is not available right now.", description: "This plan will soon be available for purchase." }));
                                                dispatch(ComingSoonPopUpAlerts("open"))
                                              } else if((package_type_id == 1 && max_staff_member == 1 && !(selectedStaff == 1))){
                                                setActiveloader((prevLoader) => ({ ...prevLoader, [item]: false }));
                                                return;
                                              }else{

                                                dispatch(PackageInvoiceApi({ package_type_id: id, package_manage_id: packagemanage.id })).then((action) => {
                                                  // let path = `/plans/${id}/${titleline}`;
                                                  // navigate(path);
                                                  dispatch(PackagePaymentsDataName(isPackageView[item]));
                                                  dispatch(PackagePaymentsData(packagemanage));
                                                  dispatch(PackagePaymentModal("open"));
                                                  setActiveloader((prevLoader) => ({ ...prevLoader, [item]: false }));
                                                })
                                              }
                                              // dispatch(PackageInvoiceApi({ package_type_id: id, package_manage_id: packagemanage.id })).then((action) => {
                                              //   // let path = `/plans/${id}/${titleline}`;
                                              //   // navigate(path);
                                              //   dispatch(PackagePaymentsDataName(isPackageView[item]));
                                              //   dispatch(PackagePaymentsData(packagemanage));
                                              //   dispatch(PackagePaymentModal("open"));
                                              // })
                                            }

                                          }}
                                        >
                                          {t(isPackageView[item] && isPackageView[item].name === "Accelerate" ? "Coming Soon" : staffUpgradePackageTexts.isstaffTitleTexts.length > 0 || freetrialText.isfreeTrialTitleTexts.length > 0 ? "Upgrade Now" : "Sign Up Now")}
                                          {/* {t(staffUpgradePackageTexts.isstaffTitleTexts.length > 0 || freetrialText.isfreeTrialTitleTexts.length > 0 ? "Upgrade Now" : "Sign Up Now")} */}
                                          {activeloader[item] && packageApiStatus && packageApiStatus.PackageInvoiceApi === "loading" && (<BarLoader color="#f1f1f1" className="d-flex w-100" />)}
                                        </a>
                                      )}
                                    </>}

                                    <a className="close btn btn-cancel cursor-pointer rounded fw-bold">{t("{{ smscount }} SMS per salon/{{ msgtype }}", { smscount: packagemanage.total_sms_per_salon, msgtype: packagemanage.type })}</a>
                                  </div>
                                  <div className="plan-items">
                                    <div className="paln-list-wr">
                                      <h5>{t("Key features:")}</h5>
                                      {/* {packagefeatureaccess && packagefeatureaccess.length > 0 ? ( */}
                                      {keyFeaturesListNew && keyFeaturesListNew.length > 0 ? (
                                        <ul className="paln-chek">
                                          {Object.keys(keyFeaturesListNew).map((itemsub, j) => {
                                            // if (j < 5) {
                                            let packagefeature = keyFeaturesListNew[itemsub];
                                            return (
                                              <div key={i + "-" + j} className="d-flex justify-content-start align-items-center">
                                                <i className="fa fa-check" aria-hidden="true" style={{ color: "#8c1c4d" }}></i>
                                                <li>{packagefeature.title}</li>
                                              </div>
                                            );
                                            // }
                                          })}
                                        </ul>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    {keyFeaturesListNew && keyFeaturesListNew.length > 5 && (
                                      <a className="package-links-tags" role="button" href="#upgrade-ac-plan" onClick={() => {
                                        const element = document.getElementById('upgrade-ac-plan');
                                        element.scrollIntoView({ behavior: 'smooth' });
                                      }}>
                                        {t("See all features")}
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                    </div>
                  </div>
                </div>
              </div>
              {isPackageFeatureView && (
                <div className="row">
                  <div className="compare-plan-title">
                    <h5 className="fw-bold text-center mt-5 mb-5" id="upgrade-ac-plan">
                      Compare Plans
                    </h5>
                  </div>
                  <div className="col-md-8 mx-auto">
                    <FeatureList view={isPackageFeatureView} isPackageView={isPackageView} />
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
      {isPackageModal && <PackagePayment />}
      {isopenBillingDetailEditForm && <BillingDetailsEditForm />}
      {isComingSoonPopUpAlerts && <ComingSoonAlertPopUps />}
    </>
  );
};

export default Package;
