import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import config from "../../../config";
import { useTranslation } from "react-i18next";
import { featureaccess } from "helpers/Functions";
import ApiStatus from "component/ApiStatus";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { toggleSidebar } from "store/slices/sidebarToggleSlice";
import { useDispatch } from "react-redux";
import { resetDropdownToggle } from "store/slices/dropdownToggleSlice";

const Sidebar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const currentUser = auth.user;
  const role_id = currentUser && currentUser.role_id;
  const access = useSelector((state) => state.usermodule.isAccess);
  const usermodule = useSelector((state) => state.usermodule.isListView);
  const UserModuleApiStatus = useSelector((state) => state.usermodule.isApiStatus);
  const isFeatureAccess = useSelector((state) => state.salon.isFeatureAccess);
  const showSidebar = useSelector((state) => state.sidebar.showSidebar);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [toggleoff, setToggleoff] = useState(windowWidth > 991);

  useEffect(() => {
    let timeoutId = null;

    const handleResize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setWindowWidth(window.innerWidth);
        setToggleoff(window.innerWidth > 991);
      }, 200);
    };

    window.addEventListener("resize", handleResize, { passive: true });

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("resize", handleResize, { passive: true });
    };
  }, []);

  useEffect(() => {
    if (!showSidebar) {
      const timeoutId = setTimeout(() => {
        dispatch(toggleSidebar(false));
      }, 50000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [showSidebar, dispatch]);

  return (
    <>
      <aside className={`${toggleoff || !showSidebar ? "sidenav-bar" : "sidenav-bar-close"}`}>
        <div className="sidenav-logo py-4 text-center">
          {currentUser.role_id != 8 ? (
            <Link to={config.basePath + "/dashboard"}>
              <img src={config.imagepath + "logo-small.png"} alt="" />
            </Link>
          ) : (
            <Link to={config.basePath + "/calender"}>
              <img src={config.imagepath + "logo-small.png"} alt="" />
            </Link>
          )}
        </div>
        <div className="side-menu-wrapper">
        <div className="sidemenu">
          <ul className="list-unstyled p-0 m-0 text-center">
            {/* <li>
            <NavLink to={config.basePath + "/dashboard"} data-bs-toggle="tooltip" data-bs-placement="right" title={t("Dashboard")}>
              <span className="icon">
                <img src={config.imagepath + "dashboard.png"} alt="" />
              </span>
              <span className="d-lg-none ps-3">{t("Dashboard")}</span>
            </NavLink>
          </li> */}
            {UserModuleApiStatus &&
              UserModuleApiStatus.usermoduleListViewApi === "succeeded" &&
              usermodule.length > 0 &&
              usermodule.map((module, i) => {
                const isCheckAccess = true;
                if (isCheckAccess) {
                  const renderTooltip = (props) => (
                    <Tooltip id={`tooltip-${i}`} {...props}>
                      {t(module.title)}
                    </Tooltip>
                  );
                  if (currentUser && !(currentUser.role_id === 8 && (module.route === "dashboard" || module.route === "staff" || module.route === "reports"))) {
                    return (
                      <li key={i} style={{ marginBottom: windowWidth <= 991 && i === usermodule.length - 1 ? "80px" : "" }}>
                        {windowWidth > 991 ? (
                          <OverlayTrigger placement="right" delay={{ show: 250, hide: 300 }} overlay={renderTooltip}>
                            <div> 
                              <NavLink to={config.basePath + "/" + module.route}>
                                <span className="icon" onClick={() => { dispatch(toggleSidebar(false)); dispatch(resetDropdownToggle(false)); }}>
                                  <img src={config.imagepath + module.icon} alt="" />
                                </span>
                                <span className="d-none ps-3">{t(module.title)}</span>
                              </NavLink>
                              {module && module.route === "subscriptions" ? <span className="sidebar-beta-icon-label">Beta</span> : null}
                            </div>
                          </OverlayTrigger>
                        ) : (
                          <NavLink to={config.basePath + "/" + module.route} data-bs-toggle="tooltip" data-bs-placement="right"
                            onClick={() => {
                              dispatch(toggleSidebar(false));
                              dispatch(resetDropdownToggle(false));
                            }}>
                            <span className="icon">
                              <img src={config.imagepath + module.icon} alt="" />
                            </span>
                            <span className="d-sm-none ps-3">{t(module.title)}</span>
                            {module && module.route === "subscriptions" ? <span className="sidebar-beta-icon-label cursor-pointer">Beta</span> : null}
                          </NavLink>
                        )}
                      </li>
                    );
                  }
                }
              })}
            <ApiStatus actionType="sidebar_menu" />
          </ul>
        </div>
        </div>
      </aside>
      {(windowWidth <= 991 && !showSidebar) && (
        <div className="backdrop show z-index-1021" onClick={() => dispatch(toggleSidebar())}></div>
      )}
    </>
  );
};

export default Sidebar;


{/*
Tooltip Old code
{UserModuleApiStatus &&
    UserModuleApiStatus.usermoduleListViewApi === "succeeded" &&
    usermodule.length > 0 &&
    usermodule.map((module, i) => {
      const isCheckAccess = true;
      if (isCheckAccess) {
        const renderTooltip = (props) => (
          <Tooltip id="button-tooltip" {...props}>
            {t(module.title)}
          </Tooltip>
        );
        if (currentUser && !(currentUser.role_id === 8 && (module.route === "dashboard" || module.route === "staff" || module.route === "reports"))) {
          return (
            <li key={i}>
              {windowWidth > 991 && showSidebar ? (
                <OverlayTrigger placement="right" delay={{ show: 250, hide: 300 }} overlay={renderTooltip}>
                  <>
                    <NavLink to={config.basePath + "/" + module.route} data-bs-toggle="tooltip" data-bs-placement="right">
                      <span
                        className="icon"
                        onClick={() => {
                          dispatch(toggleSidebar(false));
                          dispatch(resetDropdownToggle(false));
                        }}
                      >
                        <img src={config.imagepath + module.icon} alt="" />
                      </span>
                      <span className="d-none ps-3">{t(module.title)}</span>
                    </NavLink>
                    {module && module.route === "resource" ? <span className="sidebar-beta-icon-label">Beta</span> : ""}
                  </>
                </OverlayTrigger>
              ) : (
                <>
                  <NavLink to={config.basePath + "/" + module.route} data-bs-toggle="tooltip" data-bs-placement="right">
                    <span
                      className="icon"
                      onClick={() => {
                        dispatch(toggleSidebar(false));
                        dispatch(resetDropdownToggle(false));
                      }}
                    >
                      <img src={config.imagepath + module.icon} alt="" />

                    </span>
                    <span className="d-none ps-3">{t(module.title)}</span>
                  </NavLink>
                  {module && module.route === "resource" ? <span className="sidebar-beta-icon-label cursor-pointer">Beta</span> : ""}
                </>
              )}
            </li>
          );
        }
      }
    })}
*/}
